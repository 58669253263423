import React from "react";
import {
  Datagrid,
  FunctionField,
  List as RAList,
  ListProps,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";

const List: React.FC<ListProps> = (props) => (
  <RAList {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <FunctionField label="Price" render={({ price }: any) => price / 100} />
      <ReferenceField source="category" reference="Category">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="group" reference="Group">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="plantType" reference="PlantType">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="manufacturer" reference="Manufacturer">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="unit" reference="Unit">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="ouncesPerUnit" />
    </Datagrid>
  </RAList>
);

export default List;
