import React from "react";
import {
  BooleanField,
  EmailField,
  FunctionField,
  ImageField,
  SelectField,
  Show as RAShow,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { EMPTY_TEXT, USERNAME_EMPTY_TEXT } from "../../theme/constants";

const Show: React.FC<ShowProps> = (props) => (
  <RAShow {...props}>
    <SimpleShowLayout>
      <ImageField
        source="imageFile.url"
        title="imageFile.name"
        addLabel={false}
      />
      <FunctionField
        label="Full name"
        render={({ firstName, lastName }: any) =>
          firstName && lastName
            ? `${firstName} ${lastName}`
            : USERNAME_EMPTY_TEXT
        }
      />
      <SelectField
        source="role"
        choices={[
          { id: "ADMIN", name: "Admin" },
          { id: "CUSTOMER", name: "Customer" },
          { id: "DRIVER", name: "Driver" },
        ]}
      />
      <TextField source="phoneNumber" emptyText={EMPTY_TEXT} />
      <EmailField source="email" emptyText={EMPTY_TEXT} />
      <BooleanField source="identified" />
    </SimpleShowLayout>
  </RAShow>
);

export default Show;
