import React from "react";
import {
  DateTimeInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from "react-admin";
import PriceInput from "../../components/PriceInput";
import { renderUserField } from "../../utils/common";

const Form: React.FC<Partial<SimpleFormProps>> = (props) => {
  return (
    <SimpleForm {...props}>
      <DateTimeInput source="submittedAt" />
      <ReferenceInput
        label="Submitted by"
        source="userSubmitted"
        reference="User"
        validate={required()}
      >
        <SelectInput optionText={renderUserField} />
      </ReferenceInput>
      <DateTimeInput source="takenAt" />
      <ReferenceInput label="Driver" source="userTaken" reference="User">
        <SelectInput optionText={renderUserField} />
      </ReferenceInput>
      <DateTimeInput source="completedAt" />
      <TextInput source="passenger" />
      <DateTimeInput source="cancelledAt" />
      <ReferenceInput
        label="Cancelled by"
        source="userCancelled"
        reference="User"
      >
        <SelectInput optionText={renderUserField} />
      </ReferenceInput>
      <PriceInput source="price" label="Total price" />
    </SimpleForm>
  );
};

export default Form;
