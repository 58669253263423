import React from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List as RAList,
  ListProps,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";
import { EMPTY_TEXT } from "../../theme/constants";
import { renderUserField } from "../../utils/common";
import TrueIcon from "@material-ui/icons/Done";
import FalseIcon from "@material-ui/icons/Clear";

const List: React.FC<ListProps> = (props) => (
  <RAList {...props}>
    <Datagrid rowClick="show">
      <NumberField source="beatifiedId" label="ID" />
      <DateField showTime source="submittedAt" />
      <ReferenceField
        source="userSubmitted"
        reference="User"
        label="Submitted by"
      >
        <FunctionField render={renderUserField} />
      </ReferenceField>
      <DateField showTime source="takenAt" emptyText={EMPTY_TEXT} />
      <ReferenceField source="userTaken" reference="User" label="Driver">
        <FunctionField
          label="Driver"
          render={renderUserField}
          emptyText={EMPTY_TEXT}
        />
      </ReferenceField>
      <DateField showTime source="completedAt" emptyText={EMPTY_TEXT} />
      <TextField source="passenger" emptyText={EMPTY_TEXT} />
      <FunctionField
        label="Total price"
        render={({ price }: any) => price / 100}
      />
      <FunctionField
        label="Cancelled"
        render={(record: any) => (record.cancelledAt ? <TrueIcon /> : <FalseIcon />)}
        emptyText={EMPTY_TEXT}
      />
    </Datagrid>
  </RAList>
);

export default List;
