import Icon from '@material-ui/icons/LineWeight';
import { ResourceProps } from "react-admin";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

const resource: Partial<ResourceProps> = {
  list: List,
  create: Create,
  edit: Edit,
  icon: Icon,
};

export default resource;
