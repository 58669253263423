import React from "react";
import {
  FunctionField,
  ImageField,
  NumberField,
  ReferenceField,
  Show as RAShow,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const Show: React.FC<ShowProps> = (props) => (
  <RAShow {...props}>
    <SimpleShowLayout>
      <ImageField source="imageFile.url" title="imageFile.name" addLabel={false} />
      <TextField source="name" />
      <FunctionField label="Price" render={({ price }: any) => price / 100} />
      <TextField source="description" />
      <ReferenceField source="category" reference="Category">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="group" reference="Group">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="plantType" reference="PlantType">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="manufacturer" reference="Manufacturer">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="unit" reference="Unit">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="ouncesPerUnit" />
    </SimpleShowLayout>
  </RAShow>
);

export default Show;
