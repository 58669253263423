import { ResourceProps } from "react-admin";
import Create from "./Create";
import Edit from "./Edit";

const resource: Partial<ResourceProps> = {
  create: Create,
  edit: Edit,
};

export default resource;
