import React from "react";
import { Admin, Loading, Resource } from "react-admin";
import useAuthProvider from "./hooks/useAuthProvider";
import useDataProvider from "./hooks/useDataProvider";
import categories from "./resources/categories";
import groups from "./resources/groups";
import items from "./resources/items";
import manufacturers from "./resources/manufacturers";
import plantTypes from "./resources/plantTypes";
import units from "./resources/units";
import users from "./resources/users";
import cartItems from "./resources/cartItems";
import orders from "./resources/orders";
import parameters from './resources/parameters'
import client from "./services/apolloClient";
import { useUserQuery } from "./generated/graphql";

const App: React.FC = () => {
  const dataProvider = useDataProvider(client);
  const authProvider = useAuthProvider();

  useUserQuery();

  if (!dataProvider) {
    return <Loading />;
  }

  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="Item" {...items} />
      <Resource name="Category" {...categories} />
      <Resource name="Group" {...groups} />
      <Resource name="PlantType" {...plantTypes} />
      <Resource name="Manufacturer" {...manufacturers} />
      <Resource name="Unit" {...units} />
      <Resource name="User" {...users} />
      <Resource name="CartItem" {...cartItems} />
      <Resource name="Order" {...orders} />
      <Resource name="Parameter" {...parameters} />
    </Admin>
  );
};

export default App;
