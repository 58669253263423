import React from "react";
import {
  BooleanField,
  Datagrid,
  EmailField,
  Filter,
  FilterProps,
  FunctionField,
  List as RAList,
  ListProps,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { Role } from "../../generated/graphql";
import { EMPTY_TEXT, USERNAME_EMPTY_TEXT } from "../../theme/constants";

const UserFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      source="role"
      choices={[
        { id: "ADMIN", name: "Admin" },
        { id: "CUSTOMER", name: "Customer" },
        { id: "DRIVER", name: "Driver" },
      ]}
      alwaysOn
      value={Role.Customer}
    />
  </Filter>
);

const List: React.FC<ListProps> = (props) => (
  <RAList {...props} filters={<UserFilter />}>
    <Datagrid rowClick="show">
      <FunctionField
        label="Name"
        render={({ firstName, lastName }: any) =>
          firstName && lastName
            ? `${firstName} ${lastName}`
            : USERNAME_EMPTY_TEXT
        }
      />
      <SelectField
        source="role"
        choices={[
          { id: "ADMIN", name: "Admin" },
          { id: "CUSTOMER", name: "Customer" },
          { id: "DRIVER", name: "Driver" },
        ]}
      />
      <TextField source="phoneNumber" emptyText={EMPTY_TEXT} />
      <EmailField source="email" emptyText={EMPTY_TEXT} />
      <BooleanField source="identified" />
    </Datagrid>
  </RAList>
);

export default List;
