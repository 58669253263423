import React, { memo, useCallback, useMemo } from "react";
import { NumberInput, required } from "react-admin";
import { priceValidation } from "../utils/validators";

type Props = React.ComponentProps<typeof NumberInput>;

const PriceInput: React.FC<Props> = (props) => {
  const validators = useMemo<Props["validate"]>(
    () => [required(), priceValidation],
    []
  );

  const format = useCallback((v: number) => v / 100, []);

  const parse = useCallback((v: string) => parseFloat(v) * 100, []);

  return (
    <NumberInput
      format={format}
      parse={parse}
      validate={validators}
      {...props}
    />
  );
};

export default memo(PriceInput);
