import React from "react";
import {
  BooleanInput,
  FileInput,
  ImageField,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from "react-admin";

const Form: React.FC<Partial<SimpleFormProps>> = (props) => {
  return (
    <SimpleForm {...props}>
      <FileInput source="imageFile" accept="image/*">
        <ImageField source="url" title="name" />
      </FileInput>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <SelectInput
        source="role"
        choices={[
          { id: "ADMIN", name: "Admin" },
          { id: "CUSTOMER", name: "Customer" },
          { id: "DRIVER", name: "Driver" },
        ]}
      />
      <TextInput source="phoneNumber" />
      <TextInput source="email" type="email" />
      <TextInput source="uuid" label="Firebase UID" />
      <BooleanInput source="identified" />
    </SimpleForm>
  );
};

export default Form;
