import React from "react";
import { Create as RACreate, CreateProps } from "react-admin";
import Form from "./Form";

const Create: React.FC<CreateProps> = (props) => (
  <RACreate {...props}>
    <Form redirect="list" />
  </RACreate>
);

export default Create;
