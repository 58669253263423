import React from "react";
import {
  FileInput,
  ImageField,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Validator,
} from "react-admin";
import PriceInput from "../../components/PriceInput";
import { MAX_OUNCES_PER_UNIT } from "../../theme/constants";

const OUNCES_PER_UNIT_INPUT_STEP = 0.1;

const ouncesPerUnitValidation: Validator = (value) => {
  if (value > MAX_OUNCES_PER_UNIT) {
    return `Ounces per unit can not be more than ${MAX_OUNCES_PER_UNIT}`;
  }
};

const Form: React.FC<Partial<SimpleFormProps>> = (props) => {
  return (
    <SimpleForm {...props}>
      <FileInput source="imageFile" accept="image/*">
        <ImageField source="url" title="name" />
      </FileInput>
      <TextInput source="name" validate={required()} />
      <PriceInput source="price" />
      <TextInput source="description" options={{ multiline: true }} />
      <ReferenceInput
        source="category"
        reference="Category"
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="group" reference="Group" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="plantType"
        reference="PlantType"
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="manufacturer"
        reference="Manufacturer"
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="unit" reference="Unit" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput
        source="ouncesPerUnit"
        validate={[required(), ouncesPerUnitValidation]}
        min={0}
        max={MAX_OUNCES_PER_UNIT}
        step={OUNCES_PER_UNIT_INPUT_STEP}
      />
    </SimpleForm>
  );
};

export default Form;
