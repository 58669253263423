import { ApolloClient } from "@apollo/client";
import { useDataProvider as usePrismaDataProvider } from "@ra-data-prisma/dataprovider";
import { convertLegacyDataProvider, DataProvider } from "react-admin";
import {
  extendParamsWithFile,
  extendParamsWithFilter,
} from "../utils/data"

export default function useDataProvider(
  client: ApolloClient<any>
): DataProvider | undefined {
  const legacyDataProvider = usePrismaDataProvider({
    client,
    aliasPrefix: "admin",
  });

  if (legacyDataProvider) {
    const dataProvider = convertLegacyDataProvider(legacyDataProvider);

    return {
      ...dataProvider,
      create: async (resource, params) =>
        dataProvider.create(
          resource,
          await extendParamsWithFile(params, client)
        ),
      getList: async (resource, params) =>
        dataProvider.getList(
          resource,
          extendParamsWithFilter(resource, params)
        ),
      update: async (resource, params) =>
        dataProvider.update(
          resource,
          await extendParamsWithFile(params, client)
        ),
    };
  }
}
