import React from "react";
import { Edit as RAEdit, EditProps } from "react-admin";
import OrderTitle from "../../components/OrderTitle";
import Form from "./Form";

const Edit: React.FC<EditProps> = (props) => (
  <RAEdit {...props} title={<OrderTitle />}>
    <Form />
  </RAEdit>
);

export default Edit;
