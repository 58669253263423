import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  name: Scalars['String'];
  imageFile?: Maybe<File>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  category: Category;
  group?: Maybe<Group>;
  plantType?: Maybe<PlantType>;
  manufacturer?: Maybe<Manufacturer>;
  unit?: Maybe<Unit>;
  imageFile?: Maybe<File>;
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PlantType = {
  __typename?: 'PlantType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Unit = {
  __typename?: 'Unit';
  id: Scalars['String'];
  name: Scalars['String'];
  numerator: Scalars['Int'];
  denominator: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role: Role;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  docFile?: Maybe<File>;
  imageFile?: Maybe<File>;
};

export type IdentifyUserResult = User | UserIdentificationError;

export type CartItem = {
  __typename?: 'CartItem';
  id: Scalars['String'];
  count: Scalars['Int'];
  item: Item;
  userId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
};

export type AddItemToCartResult = CartItem | OuncesLimitExceededError;

export type Order = {
  __typename?: 'Order';
  id: Scalars['Int'];
  beatifiedId?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  submittedAt: Scalars['DateTime'];
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  userSubmitted: User;
  userSubmittedId: Scalars['String'];
  userTaken?: Maybe<User>;
  userCancelled?: Maybe<User>;
  passenger?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  eta?: Maybe<Scalars['DateTime']>;
  cartItems: Array<CartItem>;
};


export type OrderCartItemsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CartItemWhereUniqueInput>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['String'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  item?: Maybe<Item>;
  userDocument?: Maybe<User>;
  userImage?: Maybe<User>;
  url: Scalars['String'];
};

export type FilePayload = {
  __typename?: 'FilePayload';
  file: File;
  url: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  type: NotificationType;
  orderNotification?: Maybe<OrderNotification>;
  user: User;
};

export type OrderNotification = {
  __typename?: 'OrderNotification';
  id: Scalars['String'];
  order?: Maybe<Order>;
};

export type DeviceToken = {
  __typename?: 'DeviceToken';
  id: Scalars['String'];
  token: Scalars['String'];
  user: User;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  latitudeDelta?: Maybe<Scalars['Float']>;
  longitudeDelta?: Maybe<Scalars['Float']>;
  string?: Maybe<Scalars['String']>;
};

export type Parameter = {
  __typename?: 'Parameter';
  id: Scalars['String'];
  key: ParameterKey;
  type: ParameterType;
  name: Scalars['String'];
  value: Scalars['Int'];
};

export type OuncesLimitExceededError = {
  __typename?: 'OuncesLimitExceededError';
  currentOunces: Scalars['Float'];
};

export type UserIdentificationError = {
  __typename?: 'UserIdentificationError';
  message: Scalars['String'];
};

export type CategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CategoryWhereInput = {
  AND?: Maybe<Array<CategoryWhereInput>>;
  OR?: Maybe<Array<CategoryWhereInput>>;
  NOT?: Maybe<Array<CategoryWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  imageFile?: Maybe<FileWhereInput>;
  imageFileId?: Maybe<StringNullableFilter>;
  items?: Maybe<ItemListRelationFilter>;
};

export type CategoryOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  imageFileId?: Maybe<SortOrder>;
};

export type CategoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  imageFile?: Maybe<FileCreateNestedOneWithoutCategoryInput>;
  items?: Maybe<ItemCreateNestedManyWithoutCategoryInput>;
};

export type CategoryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutCategoryInput>;
  items?: Maybe<ItemUpdateManyWithoutCategoryInput>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type CategoryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type GroupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GroupWhereInput = {
  AND?: Maybe<Array<GroupWhereInput>>;
  OR?: Maybe<Array<GroupWhereInput>>;
  NOT?: Maybe<Array<GroupWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  items?: Maybe<ItemListRelationFilter>;
};

export type GroupOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type GroupCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  items?: Maybe<ItemCreateNestedManyWithoutGroupInput>;
};

export type GroupUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<ItemUpdateManyWithoutGroupInput>;
};

export type GroupUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type QueryItemsWhereInput = {
  category?: Maybe<CategoryWhereInput>;
};

export type ItemWhereInput = {
  AND?: Maybe<Array<ItemWhereInput>>;
  OR?: Maybe<Array<ItemWhereInput>>;
  NOT?: Maybe<Array<ItemWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<IntFilter>;
  ouncesPerUnit?: Maybe<FloatFilter>;
  description?: Maybe<StringNullableFilter>;
  category?: Maybe<CategoryWhereInput>;
  categoryId?: Maybe<StringFilter>;
  group?: Maybe<GroupWhereInput>;
  groupId?: Maybe<StringNullableFilter>;
  plantType?: Maybe<PlantTypeWhereInput>;
  plantTypeId?: Maybe<StringNullableFilter>;
  manufacturer?: Maybe<ManufacturerWhereInput>;
  manufacturerId?: Maybe<StringNullableFilter>;
  unit?: Maybe<UnitWhereInput>;
  unitId?: Maybe<StringNullableFilter>;
  imageFile?: Maybe<FileWhereInput>;
  imageFileId?: Maybe<StringNullableFilter>;
  cartItems?: Maybe<CartItemListRelationFilter>;
};

export type ItemOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  ouncesPerUnit?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  category?: Maybe<CategoryOrderByInput>;
  categoryId?: Maybe<SortOrder>;
  group?: Maybe<GroupOrderByInput>;
  groupId?: Maybe<SortOrder>;
  plantType?: Maybe<PlantTypeOrderByInput>;
  plantTypeId?: Maybe<SortOrder>;
  manufacturer?: Maybe<ManufacturerOrderByInput>;
  manufacturerId?: Maybe<SortOrder>;
  unit?: Maybe<UnitOrderByInput>;
  unitId?: Maybe<SortOrder>;
  imageFileId?: Maybe<SortOrder>;
};

export type ItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutItemsInput;
  group?: Maybe<GroupCreateNestedOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeCreateNestedOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerCreateNestedOneWithoutItemsInput>;
  unit?: Maybe<UnitCreateNestedOneWithoutItemsInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutItemInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutItemInput>;
};

export type ItemUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutItemsInput>;
  group?: Maybe<GroupUpdateOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeUpdateOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerUpdateOneWithoutItemsInput>;
  unit?: Maybe<UnitUpdateOneWithoutItemsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutItemInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutItemInput>;
};

export type ItemUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ManufacturerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ManufacturerWhereInput = {
  AND?: Maybe<Array<ManufacturerWhereInput>>;
  OR?: Maybe<Array<ManufacturerWhereInput>>;
  NOT?: Maybe<Array<ManufacturerWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  items?: Maybe<ItemListRelationFilter>;
};

export type ManufacturerOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ManufacturerCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  items?: Maybe<ItemCreateNestedManyWithoutManufacturerInput>;
};

export type ManufacturerUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<ItemUpdateManyWithoutManufacturerInput>;
};

export type ManufacturerUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PlantTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PlantTypeWhereInput = {
  AND?: Maybe<Array<PlantTypeWhereInput>>;
  OR?: Maybe<Array<PlantTypeWhereInput>>;
  NOT?: Maybe<Array<PlantTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  items?: Maybe<ItemListRelationFilter>;
};

export type PlantTypeOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type PlantTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  items?: Maybe<ItemCreateNestedManyWithoutPlantTypeInput>;
};

export type PlantTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<ItemUpdateManyWithoutPlantTypeInput>;
};

export type PlantTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type UnitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UnitWhereInput = {
  AND?: Maybe<Array<UnitWhereInput>>;
  OR?: Maybe<Array<UnitWhereInput>>;
  NOT?: Maybe<Array<UnitWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  numerator?: Maybe<IntFilter>;
  denominator?: Maybe<IntFilter>;
  items?: Maybe<ItemListRelationFilter>;
};

export type UnitOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  numerator?: Maybe<SortOrder>;
  denominator?: Maybe<SortOrder>;
};

export type UnitCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  numerator?: Maybe<Scalars['Int']>;
  denominator?: Maybe<Scalars['Int']>;
  items?: Maybe<ItemCreateNestedManyWithoutUnitInput>;
};

export type UnitUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  numerator?: Maybe<IntFieldUpdateOperationsInput>;
  denominator?: Maybe<IntFieldUpdateOperationsInput>;
  items?: Maybe<ItemUpdateManyWithoutUnitInput>;
};

export type UnitUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  numerator?: Maybe<IntFieldUpdateOperationsInput>;
  denominator?: Maybe<IntFieldUpdateOperationsInput>;
};

export enum Role {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Driver = 'DRIVER'
}


export type UserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  docFile?: Maybe<FileUpdateOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileUpdateOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenUpdateManyWithoutUserInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderUpdateManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderUpdateManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderUpdateManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  uuid?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  identified?: Maybe<BoolNullableFilter>;
  role?: Maybe<EnumRoleFilter>;
  viewedNotificationsAt?: Maybe<DateTimeNullableFilter>;
  docFile?: Maybe<FileWhereInput>;
  docFileId?: Maybe<StringNullableFilter>;
  imageFile?: Maybe<FileWhereInput>;
  imageFileId?: Maybe<StringNullableFilter>;
  deviceTokens?: Maybe<DeviceTokenListRelationFilter>;
  cartItems?: Maybe<CartItemListRelationFilter>;
  ordersSubmitted?: Maybe<OrderListRelationFilter>;
  ordersTaken?: Maybe<OrderListRelationFilter>;
  ordersCancelled?: Maybe<OrderListRelationFilter>;
  notifications?: Maybe<NotificationListRelationFilter>;
};

export type UserOrderByInput = {
  id?: Maybe<SortOrder>;
  uuid?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  identified?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  viewedNotificationsAt?: Maybe<SortOrder>;
  docFile?: Maybe<FileOrderByInput>;
  docFileId?: Maybe<SortOrder>;
  imageFileId?: Maybe<SortOrder>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  docFile?: Maybe<FileCreateNestedOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderCreateNestedManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderCreateNestedManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderCreateNestedManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationCreateNestedManyWithoutUserInput>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QueryCartItemsWhereInput = {
  userId?: Maybe<StringNullableFilter>;
  orderId?: Maybe<IntNullableFilter>;
};

export type CartItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CartItemWhereInput = {
  AND?: Maybe<Array<CartItemWhereInput>>;
  OR?: Maybe<Array<CartItemWhereInput>>;
  NOT?: Maybe<Array<CartItemWhereInput>>;
  id?: Maybe<StringFilter>;
  count?: Maybe<IntFilter>;
  item?: Maybe<ItemWhereInput>;
  itemId?: Maybe<StringFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<IntNullableFilter>;
};

export type CartItemOrderByInput = {
  id?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
  item?: Maybe<ItemOrderByInput>;
  itemId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByInput>;
  userId?: Maybe<SortOrder>;
  order?: Maybe<OrderOrderByInput>;
  orderId?: Maybe<SortOrder>;
};

export type CartItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  item: ItemCreateNestedOneWithoutCartItemsInput;
  user?: Maybe<UserCreateNestedOneWithoutCartItemsInput>;
  order?: Maybe<OrderCreateNestedOneWithoutCartItemsInput>;
};

export type CartItemUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
  item?: Maybe<ItemUpdateOneRequiredWithoutCartItemsInput>;
  user?: Maybe<UserUpdateOneWithoutCartItemsInput>;
  order?: Maybe<OrderUpdateOneWithoutCartItemsInput>;
};

export type CartItemUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  beatifiedId?: Maybe<Scalars['Int']>;
};

export type OrderWhereInput = {
  AND?: Maybe<Array<OrderWhereInput>>;
  OR?: Maybe<Array<OrderWhereInput>>;
  NOT?: Maybe<Array<OrderWhereInput>>;
  id?: Maybe<IntFilter>;
  beatifiedId?: Maybe<IntNullableFilter>;
  price?: Maybe<IntFilter>;
  submittedAt?: Maybe<DateTimeFilter>;
  takenAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  eta?: Maybe<DateTimeNullableFilter>;
  passenger?: Maybe<StringNullableFilter>;
  userSubmitted?: Maybe<UserWhereInput>;
  userSubmittedId?: Maybe<StringFilter>;
  userTaken?: Maybe<UserWhereInput>;
  userTakenId?: Maybe<StringNullableFilter>;
  userCancelled?: Maybe<UserWhereInput>;
  userCancelledId?: Maybe<StringNullableFilter>;
  address?: Maybe<AddressWhereInput>;
  addressId?: Maybe<StringNullableFilter>;
  driverAddress?: Maybe<AddressWhereInput>;
  driverAddressId?: Maybe<StringNullableFilter>;
  notifications?: Maybe<OrderNotificationListRelationFilter>;
  cartItems?: Maybe<CartItemListRelationFilter>;
};

export type OrderOrderByInput = {
  id?: Maybe<SortOrder>;
  beatifiedId?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  takenAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  eta?: Maybe<SortOrder>;
  passenger?: Maybe<SortOrder>;
  userSubmitted?: Maybe<UserOrderByInput>;
  userSubmittedId?: Maybe<SortOrder>;
  userTaken?: Maybe<UserOrderByInput>;
  userTakenId?: Maybe<SortOrder>;
  userCancelled?: Maybe<UserOrderByInput>;
  userCancelledId?: Maybe<SortOrder>;
  address?: Maybe<AddressOrderByInput>;
  addressId?: Maybe<SortOrder>;
  driverAddressId?: Maybe<SortOrder>;
};

export type OrderUpdateInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userSubmitted?: Maybe<UserUpdateOneRequiredWithoutOrdersSubmittedInput>;
  userTaken?: Maybe<UserUpdateOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserUpdateOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressUpdateOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressUpdateOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationUpdateManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutOrderInput>;
};

export type OrderCreateInput = {
  beatifiedId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<Scalars['String']>;
  userSubmitted: UserCreateNestedOneWithoutOrdersSubmittedInput;
  userTaken?: Maybe<UserCreateNestedOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserCreateNestedOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressCreateNestedOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressCreateNestedOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationCreateNestedManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutOrderInput>;
};

export type OrderUpdateManyMutationInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export enum NotificationType {
  OrderTaken = 'orderTaken',
  OrderDelivered = 'orderDelivered',
  OrderCreated = 'orderCreated',
  OrderCancelledByClient = 'orderCancelledByClient',
  OrderCancelledByDriver = 'orderCancelledByDriver'
}

export enum ParameterKey {
  TaxAndFees = 'TAX_AND_FEES',
  DeliveryPrice = 'DELIVERY_PRICE'
}

export enum ParameterType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE'
}

export type ParameterWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  key?: Maybe<ParameterKey>;
};

export type ParameterWhereInput = {
  AND?: Maybe<Array<ParameterWhereInput>>;
  OR?: Maybe<Array<ParameterWhereInput>>;
  NOT?: Maybe<Array<ParameterWhereInput>>;
  id?: Maybe<StringFilter>;
  key?: Maybe<EnumParameterKeyFilter>;
  type?: Maybe<EnumParameterTypeFilter>;
  name?: Maybe<StringFilter>;
  value?: Maybe<IntFilter>;
};

export type ParameterOrderByInput = {
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ParameterCreateInput = {
  id?: Maybe<Scalars['String']>;
  key: ParameterKey;
  type?: Maybe<ParameterType>;
  name: Scalars['String'];
  value: Scalars['Int'];
};

export type ParameterUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  key?: Maybe<EnumParameterKeyFieldUpdateOperationsInput>;
  type?: Maybe<EnumParameterTypeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ParameterUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  key?: Maybe<EnumParameterKeyFieldUpdateOperationsInput>;
  type?: Maybe<EnumParameterTypeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type FileWhereInput = {
  AND?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  NOT?: Maybe<Array<FileWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  category?: Maybe<CategoryWhereInput>;
  item?: Maybe<ItemWhereInput>;
  userDocument?: Maybe<UserWhereInput>;
  userImage?: Maybe<UserWhereInput>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type ItemListRelationFilter = {
  every?: Maybe<ItemWhereInput>;
  some?: Maybe<ItemWhereInput>;
  none?: Maybe<ItemWhereInput>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type FileCreateNestedOneWithoutCategoryInput = {
  create?: Maybe<FileCreateWithoutCategoryInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutCategoryInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type ItemCreateNestedManyWithoutCategoryInput = {
  create?: Maybe<Array<ItemCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutCategoryInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type FileUpdateOneWithoutCategoryInput = {
  create?: Maybe<FileCreateWithoutCategoryInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutCategoryInput>;
  upsert?: Maybe<FileUpsertWithoutCategoryInput>;
  connect?: Maybe<FileWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FileUpdateWithoutCategoryInput>;
};

export type ItemUpdateManyWithoutCategoryInput = {
  create?: Maybe<Array<ItemCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutCategoryInput>>;
  upsert?: Maybe<Array<ItemUpsertWithWhereUniqueWithoutCategoryInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
  set?: Maybe<Array<ItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<ItemWhereUniqueInput>>;
  delete?: Maybe<Array<ItemWhereUniqueInput>>;
  update?: Maybe<Array<ItemUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<ItemUpdateManyWithWhereWithoutCategoryInput>>;
  deleteMany?: Maybe<Array<ItemScalarWhereInput>>;
};

export type ItemCreateNestedManyWithoutGroupInput = {
  create?: Maybe<Array<ItemCreateWithoutGroupInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutGroupInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
};

export type ItemUpdateManyWithoutGroupInput = {
  create?: Maybe<Array<ItemCreateWithoutGroupInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutGroupInput>>;
  upsert?: Maybe<Array<ItemUpsertWithWhereUniqueWithoutGroupInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
  set?: Maybe<Array<ItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<ItemWhereUniqueInput>>;
  delete?: Maybe<Array<ItemWhereUniqueInput>>;
  update?: Maybe<Array<ItemUpdateWithWhereUniqueWithoutGroupInput>>;
  updateMany?: Maybe<Array<ItemUpdateManyWithWhereWithoutGroupInput>>;
  deleteMany?: Maybe<Array<ItemScalarWhereInput>>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type CartItemListRelationFilter = {
  every?: Maybe<CartItemWhereInput>;
  some?: Maybe<CartItemWhereInput>;
  none?: Maybe<CartItemWhereInput>;
};

export type CategoryCreateNestedOneWithoutItemsInput = {
  create?: Maybe<CategoryCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutItemsInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
};

export type GroupCreateNestedOneWithoutItemsInput = {
  create?: Maybe<GroupCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<GroupCreateOrConnectWithoutItemsInput>;
  connect?: Maybe<GroupWhereUniqueInput>;
};

export type PlantTypeCreateNestedOneWithoutItemsInput = {
  create?: Maybe<PlantTypeCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<PlantTypeCreateOrConnectWithoutItemsInput>;
  connect?: Maybe<PlantTypeWhereUniqueInput>;
};

export type ManufacturerCreateNestedOneWithoutItemsInput = {
  create?: Maybe<ManufacturerCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<ManufacturerCreateOrConnectWithoutItemsInput>;
  connect?: Maybe<ManufacturerWhereUniqueInput>;
};

export type UnitCreateNestedOneWithoutItemsInput = {
  create?: Maybe<UnitCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<UnitCreateOrConnectWithoutItemsInput>;
  connect?: Maybe<UnitWhereUniqueInput>;
};

export type FileCreateNestedOneWithoutItemInput = {
  create?: Maybe<FileCreateWithoutItemInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutItemInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type CartItemCreateNestedManyWithoutItemInput = {
  create?: Maybe<Array<CartItemCreateWithoutItemInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutItemInput>>;
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type CategoryUpdateOneRequiredWithoutItemsInput = {
  create?: Maybe<CategoryCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutItemsInput>;
  upsert?: Maybe<CategoryUpsertWithoutItemsInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
  update?: Maybe<CategoryUpdateWithoutItemsInput>;
};

export type GroupUpdateOneWithoutItemsInput = {
  create?: Maybe<GroupCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<GroupCreateOrConnectWithoutItemsInput>;
  upsert?: Maybe<GroupUpsertWithoutItemsInput>;
  connect?: Maybe<GroupWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<GroupUpdateWithoutItemsInput>;
};

export type PlantTypeUpdateOneWithoutItemsInput = {
  create?: Maybe<PlantTypeCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<PlantTypeCreateOrConnectWithoutItemsInput>;
  upsert?: Maybe<PlantTypeUpsertWithoutItemsInput>;
  connect?: Maybe<PlantTypeWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PlantTypeUpdateWithoutItemsInput>;
};

export type ManufacturerUpdateOneWithoutItemsInput = {
  create?: Maybe<ManufacturerCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<ManufacturerCreateOrConnectWithoutItemsInput>;
  upsert?: Maybe<ManufacturerUpsertWithoutItemsInput>;
  connect?: Maybe<ManufacturerWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ManufacturerUpdateWithoutItemsInput>;
};

export type UnitUpdateOneWithoutItemsInput = {
  create?: Maybe<UnitCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<UnitCreateOrConnectWithoutItemsInput>;
  upsert?: Maybe<UnitUpsertWithoutItemsInput>;
  connect?: Maybe<UnitWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UnitUpdateWithoutItemsInput>;
};

export type FileUpdateOneWithoutItemInput = {
  create?: Maybe<FileCreateWithoutItemInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutItemInput>;
  upsert?: Maybe<FileUpsertWithoutItemInput>;
  connect?: Maybe<FileWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FileUpdateWithoutItemInput>;
};

export type CartItemUpdateManyWithoutItemInput = {
  create?: Maybe<Array<CartItemCreateWithoutItemInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutItemInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutItemInput>>;
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutItemInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutItemInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
};

export type ItemCreateNestedManyWithoutManufacturerInput = {
  create?: Maybe<Array<ItemCreateWithoutManufacturerInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutManufacturerInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
};

export type ItemUpdateManyWithoutManufacturerInput = {
  create?: Maybe<Array<ItemCreateWithoutManufacturerInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutManufacturerInput>>;
  upsert?: Maybe<Array<ItemUpsertWithWhereUniqueWithoutManufacturerInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
  set?: Maybe<Array<ItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<ItemWhereUniqueInput>>;
  delete?: Maybe<Array<ItemWhereUniqueInput>>;
  update?: Maybe<Array<ItemUpdateWithWhereUniqueWithoutManufacturerInput>>;
  updateMany?: Maybe<Array<ItemUpdateManyWithWhereWithoutManufacturerInput>>;
  deleteMany?: Maybe<Array<ItemScalarWhereInput>>;
};

export type ItemCreateNestedManyWithoutPlantTypeInput = {
  create?: Maybe<Array<ItemCreateWithoutPlantTypeInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutPlantTypeInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
};

export type ItemUpdateManyWithoutPlantTypeInput = {
  create?: Maybe<Array<ItemCreateWithoutPlantTypeInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutPlantTypeInput>>;
  upsert?: Maybe<Array<ItemUpsertWithWhereUniqueWithoutPlantTypeInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
  set?: Maybe<Array<ItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<ItemWhereUniqueInput>>;
  delete?: Maybe<Array<ItemWhereUniqueInput>>;
  update?: Maybe<Array<ItemUpdateWithWhereUniqueWithoutPlantTypeInput>>;
  updateMany?: Maybe<Array<ItemUpdateManyWithWhereWithoutPlantTypeInput>>;
  deleteMany?: Maybe<Array<ItemScalarWhereInput>>;
};

export type ItemCreateNestedManyWithoutUnitInput = {
  create?: Maybe<Array<ItemCreateWithoutUnitInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutUnitInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
};

export type ItemUpdateManyWithoutUnitInput = {
  create?: Maybe<Array<ItemCreateWithoutUnitInput>>;
  connectOrCreate?: Maybe<Array<ItemCreateOrConnectWithoutUnitInput>>;
  upsert?: Maybe<Array<ItemUpsertWithWhereUniqueWithoutUnitInput>>;
  connect?: Maybe<Array<ItemWhereUniqueInput>>;
  set?: Maybe<Array<ItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<ItemWhereUniqueInput>>;
  delete?: Maybe<Array<ItemWhereUniqueInput>>;
  update?: Maybe<Array<ItemUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: Maybe<Array<ItemUpdateManyWithWhereWithoutUnitInput>>;
  deleteMany?: Maybe<Array<ItemScalarWhereInput>>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: Maybe<Role>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type FileUpdateOneWithoutUserDocumentInput = {
  create?: Maybe<FileCreateWithoutUserDocumentInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutUserDocumentInput>;
  upsert?: Maybe<FileUpsertWithoutUserDocumentInput>;
  connect?: Maybe<FileWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FileUpdateWithoutUserDocumentInput>;
};

export type FileUpdateOneWithoutUserImageInput = {
  create?: Maybe<FileCreateWithoutUserImageInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutUserImageInput>;
  upsert?: Maybe<FileUpsertWithoutUserImageInput>;
  connect?: Maybe<FileWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FileUpdateWithoutUserImageInput>;
};

export type DeviceTokenUpdateManyWithoutUserInput = {
  create?: Maybe<Array<DeviceTokenCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<DeviceTokenCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<DeviceTokenUpsertWithWhereUniqueWithoutUserInput>>;
  connect?: Maybe<Array<DeviceTokenWhereUniqueInput>>;
  set?: Maybe<Array<DeviceTokenWhereUniqueInput>>;
  disconnect?: Maybe<Array<DeviceTokenWhereUniqueInput>>;
  delete?: Maybe<Array<DeviceTokenWhereUniqueInput>>;
  update?: Maybe<Array<DeviceTokenUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<DeviceTokenUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<DeviceTokenScalarWhereInput>>;
};

export type CartItemUpdateManyWithoutUserInput = {
  create?: Maybe<Array<CartItemCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutUserInput>>;
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
};

export type OrderUpdateManyWithoutUserSubmittedInput = {
  create?: Maybe<Array<OrderCreateWithoutUserSubmittedInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutUserSubmittedInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutUserSubmittedInput>>;
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutUserSubmittedInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutUserSubmittedInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
};

export type OrderUpdateManyWithoutUserTakenInput = {
  create?: Maybe<Array<OrderCreateWithoutUserTakenInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutUserTakenInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutUserTakenInput>>;
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutUserTakenInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutUserTakenInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
};

export type OrderUpdateManyWithoutUserCancelledInput = {
  create?: Maybe<Array<OrderCreateWithoutUserCancelledInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutUserCancelledInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutUserCancelledInput>>;
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutUserCancelledInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutUserCancelledInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
};

export type NotificationUpdateManyWithoutUserInput = {
  create?: Maybe<Array<NotificationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<NotificationCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<NotificationUpsertWithWhereUniqueWithoutUserInput>>;
  connect?: Maybe<Array<NotificationWhereUniqueInput>>;
  set?: Maybe<Array<NotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<NotificationWhereUniqueInput>>;
  delete?: Maybe<Array<NotificationWhereUniqueInput>>;
  update?: Maybe<Array<NotificationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<NotificationUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<NotificationScalarWhereInput>>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type EnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type DeviceTokenListRelationFilter = {
  every?: Maybe<DeviceTokenWhereInput>;
  some?: Maybe<DeviceTokenWhereInput>;
  none?: Maybe<DeviceTokenWhereInput>;
};

export type OrderListRelationFilter = {
  every?: Maybe<OrderWhereInput>;
  some?: Maybe<OrderWhereInput>;
  none?: Maybe<OrderWhereInput>;
};

export type NotificationListRelationFilter = {
  every?: Maybe<NotificationWhereInput>;
  some?: Maybe<NotificationWhereInput>;
  none?: Maybe<NotificationWhereInput>;
};

export type FileOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  category?: Maybe<CategoryOrderByInput>;
  item?: Maybe<ItemOrderByInput>;
  userImage?: Maybe<UserOrderByInput>;
};

export type FileCreateNestedOneWithoutUserDocumentInput = {
  create?: Maybe<FileCreateWithoutUserDocumentInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutUserDocumentInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type FileCreateNestedOneWithoutUserImageInput = {
  create?: Maybe<FileCreateWithoutUserImageInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutUserImageInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type DeviceTokenCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<DeviceTokenCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<DeviceTokenCreateOrConnectWithoutUserInput>>;
  connect?: Maybe<Array<DeviceTokenWhereUniqueInput>>;
};

export type CartItemCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CartItemCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutUserInput>>;
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
};

export type OrderCreateNestedManyWithoutUserSubmittedInput = {
  create?: Maybe<Array<OrderCreateWithoutUserSubmittedInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutUserSubmittedInput>>;
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
};

export type OrderCreateNestedManyWithoutUserTakenInput = {
  create?: Maybe<Array<OrderCreateWithoutUserTakenInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutUserTakenInput>>;
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
};

export type OrderCreateNestedManyWithoutUserCancelledInput = {
  create?: Maybe<Array<OrderCreateWithoutUserCancelledInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutUserCancelledInput>>;
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
};

export type NotificationCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<NotificationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<NotificationCreateOrConnectWithoutUserInput>>;
  connect?: Maybe<Array<NotificationWhereUniqueInput>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type ItemCreateNestedOneWithoutCartItemsInput = {
  create?: Maybe<ItemCreateWithoutCartItemsInput>;
  connectOrCreate?: Maybe<ItemCreateOrConnectWithoutCartItemsInput>;
  connect?: Maybe<ItemWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutCartItemsInput = {
  create?: Maybe<UserCreateWithoutCartItemsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCartItemsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type OrderCreateNestedOneWithoutCartItemsInput = {
  create?: Maybe<OrderCreateWithoutCartItemsInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutCartItemsInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
};

export type ItemUpdateOneRequiredWithoutCartItemsInput = {
  create?: Maybe<ItemCreateWithoutCartItemsInput>;
  connectOrCreate?: Maybe<ItemCreateOrConnectWithoutCartItemsInput>;
  upsert?: Maybe<ItemUpsertWithoutCartItemsInput>;
  connect?: Maybe<ItemWhereUniqueInput>;
  update?: Maybe<ItemUpdateWithoutCartItemsInput>;
};

export type UserUpdateOneWithoutCartItemsInput = {
  create?: Maybe<UserCreateWithoutCartItemsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCartItemsInput>;
  upsert?: Maybe<UserUpsertWithoutCartItemsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutCartItemsInput>;
};

export type OrderUpdateOneWithoutCartItemsInput = {
  create?: Maybe<OrderCreateWithoutCartItemsInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutCartItemsInput>;
  upsert?: Maybe<OrderUpsertWithoutCartItemsInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderUpdateWithoutCartItemsInput>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type AddressWhereInput = {
  AND?: Maybe<Array<AddressWhereInput>>;
  OR?: Maybe<Array<AddressWhereInput>>;
  NOT?: Maybe<Array<AddressWhereInput>>;
  id?: Maybe<StringFilter>;
  latitude?: Maybe<FloatFilter>;
  longitude?: Maybe<FloatFilter>;
  latitudeDelta?: Maybe<FloatNullableFilter>;
  longitudeDelta?: Maybe<FloatNullableFilter>;
  string?: Maybe<StringNullableFilter>;
  order?: Maybe<OrderWhereInput>;
  driverOrder?: Maybe<OrderWhereInput>;
};

export type OrderNotificationListRelationFilter = {
  every?: Maybe<OrderNotificationWhereInput>;
  some?: Maybe<OrderNotificationWhereInput>;
  none?: Maybe<OrderNotificationWhereInput>;
};

export type AddressOrderByInput = {
  id?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  latitudeDelta?: Maybe<SortOrder>;
  longitudeDelta?: Maybe<SortOrder>;
  string?: Maybe<SortOrder>;
  driverOrder?: Maybe<OrderOrderByInput>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type UserUpdateOneRequiredWithoutOrdersSubmittedInput = {
  create?: Maybe<UserCreateWithoutOrdersSubmittedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOrdersSubmittedInput>;
  upsert?: Maybe<UserUpsertWithoutOrdersSubmittedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutOrdersSubmittedInput>;
};

export type UserUpdateOneWithoutOrdersTakenInput = {
  create?: Maybe<UserCreateWithoutOrdersTakenInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOrdersTakenInput>;
  upsert?: Maybe<UserUpsertWithoutOrdersTakenInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutOrdersTakenInput>;
};

export type UserUpdateOneWithoutOrdersCancelledInput = {
  create?: Maybe<UserCreateWithoutOrdersCancelledInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOrdersCancelledInput>;
  upsert?: Maybe<UserUpsertWithoutOrdersCancelledInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutOrdersCancelledInput>;
};

export type AddressUpdateOneWithoutOrderInput = {
  create?: Maybe<AddressCreateWithoutOrderInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutOrderInput>;
  upsert?: Maybe<AddressUpsertWithoutOrderInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUpdateWithoutOrderInput>;
};

export type AddressUpdateOneWithoutDriverOrderInput = {
  create?: Maybe<AddressCreateWithoutDriverOrderInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutDriverOrderInput>;
  upsert?: Maybe<AddressUpsertWithoutDriverOrderInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUpdateWithoutDriverOrderInput>;
};

export type OrderNotificationUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<OrderNotificationCreateWithoutOrderInput>>;
  connectOrCreate?: Maybe<Array<OrderNotificationCreateOrConnectWithoutOrderInput>>;
  upsert?: Maybe<Array<OrderNotificationUpsertWithWhereUniqueWithoutOrderInput>>;
  connect?: Maybe<Array<OrderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<OrderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<OrderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<OrderNotificationUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<OrderNotificationUpdateManyWithWhereWithoutOrderInput>>;
  deleteMany?: Maybe<Array<OrderNotificationScalarWhereInput>>;
};

export type CartItemUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<CartItemCreateWithoutOrderInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutOrderInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutOrderInput>>;
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutOrderInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
};

export type UserCreateNestedOneWithoutOrdersSubmittedInput = {
  create?: Maybe<UserCreateWithoutOrdersSubmittedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOrdersSubmittedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutOrdersTakenInput = {
  create?: Maybe<UserCreateWithoutOrdersTakenInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOrdersTakenInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutOrdersCancelledInput = {
  create?: Maybe<UserCreateWithoutOrdersCancelledInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOrdersCancelledInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type AddressCreateNestedOneWithoutOrderInput = {
  create?: Maybe<AddressCreateWithoutOrderInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutOrderInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type AddressCreateNestedOneWithoutDriverOrderInput = {
  create?: Maybe<AddressCreateWithoutDriverOrderInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutDriverOrderInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type OrderNotificationCreateNestedManyWithoutOrderInput = {
  create?: Maybe<Array<OrderNotificationCreateWithoutOrderInput>>;
  connectOrCreate?: Maybe<Array<OrderNotificationCreateOrConnectWithoutOrderInput>>;
  connect?: Maybe<Array<OrderNotificationWhereUniqueInput>>;
};

export type CartItemCreateNestedManyWithoutOrderInput = {
  create?: Maybe<Array<CartItemCreateWithoutOrderInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutOrderInput>>;
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
};

export type EnumParameterKeyFilter = {
  equals?: Maybe<ParameterKey>;
  in?: Maybe<Array<ParameterKey>>;
  notIn?: Maybe<Array<ParameterKey>>;
  not?: Maybe<NestedEnumParameterKeyFilter>;
};

export type EnumParameterTypeFilter = {
  equals?: Maybe<ParameterType>;
  in?: Maybe<Array<ParameterType>>;
  notIn?: Maybe<Array<ParameterType>>;
  not?: Maybe<NestedEnumParameterTypeFilter>;
};

export type EnumParameterKeyFieldUpdateOperationsInput = {
  set?: Maybe<ParameterKey>;
};

export type EnumParameterTypeFieldUpdateOperationsInput = {
  set?: Maybe<ParameterType>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type FileCreateWithoutCategoryInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  item?: Maybe<ItemCreateNestedOneWithoutImageFileInput>;
  userDocument?: Maybe<UserCreateNestedOneWithoutDocFileInput>;
  userImage?: Maybe<UserCreateNestedOneWithoutImageFileInput>;
};

export type FileCreateOrConnectWithoutCategoryInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutCategoryInput;
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ItemCreateWithoutCategoryInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<GroupCreateNestedOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeCreateNestedOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerCreateNestedOneWithoutItemsInput>;
  unit?: Maybe<UnitCreateNestedOneWithoutItemsInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutItemInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutItemInput>;
};

export type ItemCreateOrConnectWithoutCategoryInput = {
  where: ItemWhereUniqueInput;
  create: ItemCreateWithoutCategoryInput;
};

export type FileUpsertWithoutCategoryInput = {
  update: FileUpdateWithoutCategoryInput;
  create: FileCreateWithoutCategoryInput;
};

export type FileUpdateWithoutCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  item?: Maybe<ItemUpdateOneWithoutImageFileInput>;
  userDocument?: Maybe<UserUpdateOneWithoutDocFileInput>;
  userImage?: Maybe<UserUpdateOneWithoutImageFileInput>;
};

export type ItemUpsertWithWhereUniqueWithoutCategoryInput = {
  where: ItemWhereUniqueInput;
  update: ItemUpdateWithoutCategoryInput;
  create: ItemCreateWithoutCategoryInput;
};

export type ItemUpdateWithWhereUniqueWithoutCategoryInput = {
  where: ItemWhereUniqueInput;
  data: ItemUpdateWithoutCategoryInput;
};

export type ItemUpdateManyWithWhereWithoutCategoryInput = {
  where: ItemScalarWhereInput;
  data: ItemUpdateManyMutationInput;
};

export type ItemScalarWhereInput = {
  AND?: Maybe<Array<ItemScalarWhereInput>>;
  OR?: Maybe<Array<ItemScalarWhereInput>>;
  NOT?: Maybe<Array<ItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<IntFilter>;
  ouncesPerUnit?: Maybe<FloatFilter>;
  description?: Maybe<StringNullableFilter>;
  categoryId?: Maybe<StringFilter>;
  groupId?: Maybe<StringNullableFilter>;
  plantTypeId?: Maybe<StringNullableFilter>;
  manufacturerId?: Maybe<StringNullableFilter>;
  unitId?: Maybe<StringNullableFilter>;
  imageFileId?: Maybe<StringNullableFilter>;
};

export type ItemCreateWithoutGroupInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutItemsInput;
  plantType?: Maybe<PlantTypeCreateNestedOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerCreateNestedOneWithoutItemsInput>;
  unit?: Maybe<UnitCreateNestedOneWithoutItemsInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutItemInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutItemInput>;
};

export type ItemCreateOrConnectWithoutGroupInput = {
  where: ItemWhereUniqueInput;
  create: ItemCreateWithoutGroupInput;
};

export type ItemUpsertWithWhereUniqueWithoutGroupInput = {
  where: ItemWhereUniqueInput;
  update: ItemUpdateWithoutGroupInput;
  create: ItemCreateWithoutGroupInput;
};

export type ItemUpdateWithWhereUniqueWithoutGroupInput = {
  where: ItemWhereUniqueInput;
  data: ItemUpdateWithoutGroupInput;
};

export type ItemUpdateManyWithWhereWithoutGroupInput = {
  where: ItemScalarWhereInput;
  data: ItemUpdateManyMutationInput;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type CategoryCreateWithoutItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  imageFile?: Maybe<FileCreateNestedOneWithoutCategoryInput>;
};

export type CategoryCreateOrConnectWithoutItemsInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutItemsInput;
};

export type GroupCreateWithoutItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GroupCreateOrConnectWithoutItemsInput = {
  where: GroupWhereUniqueInput;
  create: GroupCreateWithoutItemsInput;
};

export type PlantTypeCreateWithoutItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PlantTypeCreateOrConnectWithoutItemsInput = {
  where: PlantTypeWhereUniqueInput;
  create: PlantTypeCreateWithoutItemsInput;
};

export type ManufacturerCreateWithoutItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ManufacturerCreateOrConnectWithoutItemsInput = {
  where: ManufacturerWhereUniqueInput;
  create: ManufacturerCreateWithoutItemsInput;
};

export type UnitCreateWithoutItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  numerator?: Maybe<Scalars['Int']>;
  denominator?: Maybe<Scalars['Int']>;
};

export type UnitCreateOrConnectWithoutItemsInput = {
  where: UnitWhereUniqueInput;
  create: UnitCreateWithoutItemsInput;
};

export type FileCreateWithoutItemInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  category?: Maybe<CategoryCreateNestedOneWithoutImageFileInput>;
  userDocument?: Maybe<UserCreateNestedOneWithoutDocFileInput>;
  userImage?: Maybe<UserCreateNestedOneWithoutImageFileInput>;
};

export type FileCreateOrConnectWithoutItemInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutItemInput;
};

export type CartItemCreateWithoutItemInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  user?: Maybe<UserCreateNestedOneWithoutCartItemsInput>;
  order?: Maybe<OrderCreateNestedOneWithoutCartItemsInput>;
};

export type CartItemCreateOrConnectWithoutItemInput = {
  where: CartItemWhereUniqueInput;
  create: CartItemCreateWithoutItemInput;
};

export type CategoryUpsertWithoutItemsInput = {
  update: CategoryUpdateWithoutItemsInput;
  create: CategoryCreateWithoutItemsInput;
};

export type CategoryUpdateWithoutItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutCategoryInput>;
};

export type GroupUpsertWithoutItemsInput = {
  update: GroupUpdateWithoutItemsInput;
  create: GroupCreateWithoutItemsInput;
};

export type GroupUpdateWithoutItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PlantTypeUpsertWithoutItemsInput = {
  update: PlantTypeUpdateWithoutItemsInput;
  create: PlantTypeCreateWithoutItemsInput;
};

export type PlantTypeUpdateWithoutItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ManufacturerUpsertWithoutItemsInput = {
  update: ManufacturerUpdateWithoutItemsInput;
  create: ManufacturerCreateWithoutItemsInput;
};

export type ManufacturerUpdateWithoutItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type UnitUpsertWithoutItemsInput = {
  update: UnitUpdateWithoutItemsInput;
  create: UnitCreateWithoutItemsInput;
};

export type UnitUpdateWithoutItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  numerator?: Maybe<IntFieldUpdateOperationsInput>;
  denominator?: Maybe<IntFieldUpdateOperationsInput>;
};

export type FileUpsertWithoutItemInput = {
  update: FileUpdateWithoutItemInput;
  create: FileCreateWithoutItemInput;
};

export type FileUpdateWithoutItemInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneWithoutImageFileInput>;
  userDocument?: Maybe<UserUpdateOneWithoutDocFileInput>;
  userImage?: Maybe<UserUpdateOneWithoutImageFileInput>;
};

export type CartItemUpsertWithWhereUniqueWithoutItemInput = {
  where: CartItemWhereUniqueInput;
  update: CartItemUpdateWithoutItemInput;
  create: CartItemCreateWithoutItemInput;
};

export type CartItemUpdateWithWhereUniqueWithoutItemInput = {
  where: CartItemWhereUniqueInput;
  data: CartItemUpdateWithoutItemInput;
};

export type CartItemUpdateManyWithWhereWithoutItemInput = {
  where: CartItemScalarWhereInput;
  data: CartItemUpdateManyMutationInput;
};

export type CartItemScalarWhereInput = {
  AND?: Maybe<Array<CartItemScalarWhereInput>>;
  OR?: Maybe<Array<CartItemScalarWhereInput>>;
  NOT?: Maybe<Array<CartItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  count?: Maybe<IntFilter>;
  itemId?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  orderId?: Maybe<IntNullableFilter>;
};

export type ItemCreateWithoutManufacturerInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutItemsInput;
  group?: Maybe<GroupCreateNestedOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeCreateNestedOneWithoutItemsInput>;
  unit?: Maybe<UnitCreateNestedOneWithoutItemsInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutItemInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutItemInput>;
};

export type ItemCreateOrConnectWithoutManufacturerInput = {
  where: ItemWhereUniqueInput;
  create: ItemCreateWithoutManufacturerInput;
};

export type ItemUpsertWithWhereUniqueWithoutManufacturerInput = {
  where: ItemWhereUniqueInput;
  update: ItemUpdateWithoutManufacturerInput;
  create: ItemCreateWithoutManufacturerInput;
};

export type ItemUpdateWithWhereUniqueWithoutManufacturerInput = {
  where: ItemWhereUniqueInput;
  data: ItemUpdateWithoutManufacturerInput;
};

export type ItemUpdateManyWithWhereWithoutManufacturerInput = {
  where: ItemScalarWhereInput;
  data: ItemUpdateManyMutationInput;
};

export type ItemCreateWithoutPlantTypeInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutItemsInput;
  group?: Maybe<GroupCreateNestedOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerCreateNestedOneWithoutItemsInput>;
  unit?: Maybe<UnitCreateNestedOneWithoutItemsInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutItemInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutItemInput>;
};

export type ItemCreateOrConnectWithoutPlantTypeInput = {
  where: ItemWhereUniqueInput;
  create: ItemCreateWithoutPlantTypeInput;
};

export type ItemUpsertWithWhereUniqueWithoutPlantTypeInput = {
  where: ItemWhereUniqueInput;
  update: ItemUpdateWithoutPlantTypeInput;
  create: ItemCreateWithoutPlantTypeInput;
};

export type ItemUpdateWithWhereUniqueWithoutPlantTypeInput = {
  where: ItemWhereUniqueInput;
  data: ItemUpdateWithoutPlantTypeInput;
};

export type ItemUpdateManyWithWhereWithoutPlantTypeInput = {
  where: ItemScalarWhereInput;
  data: ItemUpdateManyMutationInput;
};

export type ItemCreateWithoutUnitInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutItemsInput;
  group?: Maybe<GroupCreateNestedOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeCreateNestedOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerCreateNestedOneWithoutItemsInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutItemInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutItemInput>;
};

export type ItemCreateOrConnectWithoutUnitInput = {
  where: ItemWhereUniqueInput;
  create: ItemCreateWithoutUnitInput;
};

export type ItemUpsertWithWhereUniqueWithoutUnitInput = {
  where: ItemWhereUniqueInput;
  update: ItemUpdateWithoutUnitInput;
  create: ItemCreateWithoutUnitInput;
};

export type ItemUpdateWithWhereUniqueWithoutUnitInput = {
  where: ItemWhereUniqueInput;
  data: ItemUpdateWithoutUnitInput;
};

export type ItemUpdateManyWithWhereWithoutUnitInput = {
  where: ItemScalarWhereInput;
  data: ItemUpdateManyMutationInput;
};

export type FileCreateWithoutUserDocumentInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  category?: Maybe<CategoryCreateNestedOneWithoutImageFileInput>;
  item?: Maybe<ItemCreateNestedOneWithoutImageFileInput>;
  userImage?: Maybe<UserCreateNestedOneWithoutImageFileInput>;
};

export type FileCreateOrConnectWithoutUserDocumentInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutUserDocumentInput;
};

export type FileUpsertWithoutUserDocumentInput = {
  update: FileUpdateWithoutUserDocumentInput;
  create: FileCreateWithoutUserDocumentInput;
};

export type FileUpdateWithoutUserDocumentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneWithoutImageFileInput>;
  item?: Maybe<ItemUpdateOneWithoutImageFileInput>;
  userImage?: Maybe<UserUpdateOneWithoutImageFileInput>;
};

export type FileCreateWithoutUserImageInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  category?: Maybe<CategoryCreateNestedOneWithoutImageFileInput>;
  item?: Maybe<ItemCreateNestedOneWithoutImageFileInput>;
  userDocument?: Maybe<UserCreateNestedOneWithoutDocFileInput>;
};

export type FileCreateOrConnectWithoutUserImageInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutUserImageInput;
};

export type FileUpsertWithoutUserImageInput = {
  update: FileUpdateWithoutUserImageInput;
  create: FileCreateWithoutUserImageInput;
};

export type FileUpdateWithoutUserImageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneWithoutImageFileInput>;
  item?: Maybe<ItemUpdateOneWithoutImageFileInput>;
  userDocument?: Maybe<UserUpdateOneWithoutDocFileInput>;
};

export type DeviceTokenCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type DeviceTokenCreateOrConnectWithoutUserInput = {
  where: DeviceTokenWhereUniqueInput;
  create: DeviceTokenCreateWithoutUserInput;
};

export type DeviceTokenUpsertWithWhereUniqueWithoutUserInput = {
  where: DeviceTokenWhereUniqueInput;
  update: DeviceTokenUpdateWithoutUserInput;
  create: DeviceTokenCreateWithoutUserInput;
};

export type DeviceTokenWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  token_userId?: Maybe<DeviceTokenTokenUserIdCompoundUniqueInput>;
};

export type DeviceTokenUpdateWithWhereUniqueWithoutUserInput = {
  where: DeviceTokenWhereUniqueInput;
  data: DeviceTokenUpdateWithoutUserInput;
};

export type DeviceTokenUpdateManyWithWhereWithoutUserInput = {
  where: DeviceTokenScalarWhereInput;
  data: DeviceTokenUpdateManyMutationInput;
};

export type DeviceTokenScalarWhereInput = {
  AND?: Maybe<Array<DeviceTokenScalarWhereInput>>;
  OR?: Maybe<Array<DeviceTokenScalarWhereInput>>;
  NOT?: Maybe<Array<DeviceTokenScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  token?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
};

export type CartItemCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  item: ItemCreateNestedOneWithoutCartItemsInput;
  order?: Maybe<OrderCreateNestedOneWithoutCartItemsInput>;
};

export type CartItemCreateOrConnectWithoutUserInput = {
  where: CartItemWhereUniqueInput;
  create: CartItemCreateWithoutUserInput;
};

export type CartItemUpsertWithWhereUniqueWithoutUserInput = {
  where: CartItemWhereUniqueInput;
  update: CartItemUpdateWithoutUserInput;
  create: CartItemCreateWithoutUserInput;
};

export type CartItemUpdateWithWhereUniqueWithoutUserInput = {
  where: CartItemWhereUniqueInput;
  data: CartItemUpdateWithoutUserInput;
};

export type CartItemUpdateManyWithWhereWithoutUserInput = {
  where: CartItemScalarWhereInput;
  data: CartItemUpdateManyMutationInput;
};

export type OrderCreateWithoutUserSubmittedInput = {
  beatifiedId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<Scalars['String']>;
  userTaken?: Maybe<UserCreateNestedOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserCreateNestedOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressCreateNestedOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressCreateNestedOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationCreateNestedManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutOrderInput>;
};

export type OrderCreateOrConnectWithoutUserSubmittedInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutUserSubmittedInput;
};

export type OrderUpsertWithWhereUniqueWithoutUserSubmittedInput = {
  where: OrderWhereUniqueInput;
  update: OrderUpdateWithoutUserSubmittedInput;
  create: OrderCreateWithoutUserSubmittedInput;
};

export type OrderUpdateWithWhereUniqueWithoutUserSubmittedInput = {
  where: OrderWhereUniqueInput;
  data: OrderUpdateWithoutUserSubmittedInput;
};

export type OrderUpdateManyWithWhereWithoutUserSubmittedInput = {
  where: OrderScalarWhereInput;
  data: OrderUpdateManyMutationInput;
};

export type OrderScalarWhereInput = {
  AND?: Maybe<Array<OrderScalarWhereInput>>;
  OR?: Maybe<Array<OrderScalarWhereInput>>;
  NOT?: Maybe<Array<OrderScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  beatifiedId?: Maybe<IntNullableFilter>;
  price?: Maybe<IntFilter>;
  submittedAt?: Maybe<DateTimeFilter>;
  takenAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  eta?: Maybe<DateTimeNullableFilter>;
  passenger?: Maybe<StringNullableFilter>;
  userSubmittedId?: Maybe<StringFilter>;
  userTakenId?: Maybe<StringNullableFilter>;
  userCancelledId?: Maybe<StringNullableFilter>;
  addressId?: Maybe<StringNullableFilter>;
  driverAddressId?: Maybe<StringNullableFilter>;
};

export type OrderCreateWithoutUserTakenInput = {
  beatifiedId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<Scalars['String']>;
  userSubmitted: UserCreateNestedOneWithoutOrdersSubmittedInput;
  userCancelled?: Maybe<UserCreateNestedOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressCreateNestedOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressCreateNestedOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationCreateNestedManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutOrderInput>;
};

export type OrderCreateOrConnectWithoutUserTakenInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutUserTakenInput;
};

export type OrderUpsertWithWhereUniqueWithoutUserTakenInput = {
  where: OrderWhereUniqueInput;
  update: OrderUpdateWithoutUserTakenInput;
  create: OrderCreateWithoutUserTakenInput;
};

export type OrderUpdateWithWhereUniqueWithoutUserTakenInput = {
  where: OrderWhereUniqueInput;
  data: OrderUpdateWithoutUserTakenInput;
};

export type OrderUpdateManyWithWhereWithoutUserTakenInput = {
  where: OrderScalarWhereInput;
  data: OrderUpdateManyMutationInput;
};

export type OrderCreateWithoutUserCancelledInput = {
  beatifiedId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<Scalars['String']>;
  userSubmitted: UserCreateNestedOneWithoutOrdersSubmittedInput;
  userTaken?: Maybe<UserCreateNestedOneWithoutOrdersTakenInput>;
  address?: Maybe<AddressCreateNestedOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressCreateNestedOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationCreateNestedManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutOrderInput>;
};

export type OrderCreateOrConnectWithoutUserCancelledInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutUserCancelledInput;
};

export type OrderUpsertWithWhereUniqueWithoutUserCancelledInput = {
  where: OrderWhereUniqueInput;
  update: OrderUpdateWithoutUserCancelledInput;
  create: OrderCreateWithoutUserCancelledInput;
};

export type OrderUpdateWithWhereUniqueWithoutUserCancelledInput = {
  where: OrderWhereUniqueInput;
  data: OrderUpdateWithoutUserCancelledInput;
};

export type OrderUpdateManyWithWhereWithoutUserCancelledInput = {
  where: OrderScalarWhereInput;
  data: OrderUpdateManyMutationInput;
};

export type NotificationCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: NotificationType;
  orderNotification?: Maybe<OrderNotificationCreateNestedOneWithoutNotificationInput>;
};

export type NotificationCreateOrConnectWithoutUserInput = {
  where: NotificationWhereUniqueInput;
  create: NotificationCreateWithoutUserInput;
};

export type NotificationUpsertWithWhereUniqueWithoutUserInput = {
  where: NotificationWhereUniqueInput;
  update: NotificationUpdateWithoutUserInput;
  create: NotificationCreateWithoutUserInput;
};

export type NotificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type NotificationUpdateWithWhereUniqueWithoutUserInput = {
  where: NotificationWhereUniqueInput;
  data: NotificationUpdateWithoutUserInput;
};

export type NotificationUpdateManyWithWhereWithoutUserInput = {
  where: NotificationScalarWhereInput;
  data: NotificationUpdateManyMutationInput;
};

export type NotificationScalarWhereInput = {
  AND?: Maybe<Array<NotificationScalarWhereInput>>;
  OR?: Maybe<Array<NotificationScalarWhereInput>>;
  NOT?: Maybe<Array<NotificationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
  type?: Maybe<EnumNotificationTypeFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type DeviceTokenWhereInput = {
  AND?: Maybe<Array<DeviceTokenWhereInput>>;
  OR?: Maybe<Array<DeviceTokenWhereInput>>;
  NOT?: Maybe<Array<DeviceTokenWhereInput>>;
  id?: Maybe<StringFilter>;
  token?: Maybe<StringFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type NotificationWhereInput = {
  AND?: Maybe<Array<NotificationWhereInput>>;
  OR?: Maybe<Array<NotificationWhereInput>>;
  NOT?: Maybe<Array<NotificationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
  type?: Maybe<EnumNotificationTypeFilter>;
  orderNotification?: Maybe<OrderNotificationWhereInput>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type ItemCreateWithoutCartItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutItemsInput;
  group?: Maybe<GroupCreateNestedOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeCreateNestedOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerCreateNestedOneWithoutItemsInput>;
  unit?: Maybe<UnitCreateNestedOneWithoutItemsInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutItemInput>;
};

export type ItemCreateOrConnectWithoutCartItemsInput = {
  where: ItemWhereUniqueInput;
  create: ItemCreateWithoutCartItemsInput;
};

export type UserCreateWithoutCartItemsInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  docFile?: Maybe<FileCreateNestedOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderCreateNestedManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderCreateNestedManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderCreateNestedManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationCreateNestedManyWithoutUserInput>;
};

export type UserCreateOrConnectWithoutCartItemsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCartItemsInput;
};

export type OrderCreateWithoutCartItemsInput = {
  beatifiedId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<Scalars['String']>;
  userSubmitted: UserCreateNestedOneWithoutOrdersSubmittedInput;
  userTaken?: Maybe<UserCreateNestedOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserCreateNestedOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressCreateNestedOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressCreateNestedOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationCreateNestedManyWithoutOrderInput>;
};

export type OrderCreateOrConnectWithoutCartItemsInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutCartItemsInput;
};

export type ItemUpsertWithoutCartItemsInput = {
  update: ItemUpdateWithoutCartItemsInput;
  create: ItemCreateWithoutCartItemsInput;
};

export type ItemUpdateWithoutCartItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutItemsInput>;
  group?: Maybe<GroupUpdateOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeUpdateOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerUpdateOneWithoutItemsInput>;
  unit?: Maybe<UnitUpdateOneWithoutItemsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutItemInput>;
};

export type UserUpsertWithoutCartItemsInput = {
  update: UserUpdateWithoutCartItemsInput;
  create: UserCreateWithoutCartItemsInput;
};

export type UserUpdateWithoutCartItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  docFile?: Maybe<FileUpdateOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileUpdateOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenUpdateManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderUpdateManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderUpdateManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderUpdateManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
};

export type OrderUpsertWithoutCartItemsInput = {
  update: OrderUpdateWithoutCartItemsInput;
  create: OrderCreateWithoutCartItemsInput;
};

export type OrderUpdateWithoutCartItemsInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userSubmitted?: Maybe<UserUpdateOneRequiredWithoutOrdersSubmittedInput>;
  userTaken?: Maybe<UserUpdateOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserUpdateOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressUpdateOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressUpdateOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationUpdateManyWithoutOrderInput>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type OrderNotificationWhereInput = {
  AND?: Maybe<Array<OrderNotificationWhereInput>>;
  OR?: Maybe<Array<OrderNotificationWhereInput>>;
  NOT?: Maybe<Array<OrderNotificationWhereInput>>;
  id?: Maybe<StringFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<IntNullableFilter>;
  notification?: Maybe<NotificationWhereInput>;
  notificationId?: Maybe<StringFilter>;
};

export type UserCreateWithoutOrdersSubmittedInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  docFile?: Maybe<FileCreateNestedOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutUserInput>;
  ordersTaken?: Maybe<OrderCreateNestedManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderCreateNestedManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationCreateNestedManyWithoutUserInput>;
};

export type UserCreateOrConnectWithoutOrdersSubmittedInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutOrdersSubmittedInput;
};

export type UserUpsertWithoutOrdersSubmittedInput = {
  update: UserUpdateWithoutOrdersSubmittedInput;
  create: UserCreateWithoutOrdersSubmittedInput;
};

export type UserUpdateWithoutOrdersSubmittedInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  docFile?: Maybe<FileUpdateOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileUpdateOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenUpdateManyWithoutUserInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutUserInput>;
  ordersTaken?: Maybe<OrderUpdateManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderUpdateManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
};

export type UserCreateWithoutOrdersTakenInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  docFile?: Maybe<FileCreateNestedOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderCreateNestedManyWithoutUserSubmittedInput>;
  ordersCancelled?: Maybe<OrderCreateNestedManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationCreateNestedManyWithoutUserInput>;
};

export type UserCreateOrConnectWithoutOrdersTakenInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutOrdersTakenInput;
};

export type UserUpsertWithoutOrdersTakenInput = {
  update: UserUpdateWithoutOrdersTakenInput;
  create: UserCreateWithoutOrdersTakenInput;
};

export type UserUpdateWithoutOrdersTakenInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  docFile?: Maybe<FileUpdateOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileUpdateOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenUpdateManyWithoutUserInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderUpdateManyWithoutUserSubmittedInput>;
  ordersCancelled?: Maybe<OrderUpdateManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
};

export type UserCreateWithoutOrdersCancelledInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  docFile?: Maybe<FileCreateNestedOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderCreateNestedManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderCreateNestedManyWithoutUserTakenInput>;
  notifications?: Maybe<NotificationCreateNestedManyWithoutUserInput>;
};

export type UserCreateOrConnectWithoutOrdersCancelledInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutOrdersCancelledInput;
};

export type UserUpsertWithoutOrdersCancelledInput = {
  update: UserUpdateWithoutOrdersCancelledInput;
  create: UserCreateWithoutOrdersCancelledInput;
};

export type UserUpdateWithoutOrdersCancelledInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  docFile?: Maybe<FileUpdateOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileUpdateOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenUpdateManyWithoutUserInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderUpdateManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderUpdateManyWithoutUserTakenInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
};

export type AddressCreateWithoutOrderInput = {
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  latitudeDelta?: Maybe<Scalars['Float']>;
  longitudeDelta?: Maybe<Scalars['Float']>;
  string?: Maybe<Scalars['String']>;
  driverOrder?: Maybe<OrderCreateNestedOneWithoutDriverAddressInput>;
};

export type AddressCreateOrConnectWithoutOrderInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutOrderInput;
};

export type AddressUpsertWithoutOrderInput = {
  update: AddressUpdateWithoutOrderInput;
  create: AddressCreateWithoutOrderInput;
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AddressUpdateWithoutOrderInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  latitudeDelta?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitudeDelta?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  string?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverOrder?: Maybe<OrderUpdateOneWithoutDriverAddressInput>;
};

export type AddressCreateWithoutDriverOrderInput = {
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  latitudeDelta?: Maybe<Scalars['Float']>;
  longitudeDelta?: Maybe<Scalars['Float']>;
  string?: Maybe<Scalars['String']>;
  order?: Maybe<OrderCreateNestedOneWithoutAddressInput>;
};

export type AddressCreateOrConnectWithoutDriverOrderInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutDriverOrderInput;
};

export type AddressUpsertWithoutDriverOrderInput = {
  update: AddressUpdateWithoutDriverOrderInput;
  create: AddressCreateWithoutDriverOrderInput;
};

export type AddressUpdateWithoutDriverOrderInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  latitudeDelta?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitudeDelta?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  string?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutAddressInput>;
};

export type OrderNotificationCreateWithoutOrderInput = {
  id?: Maybe<Scalars['String']>;
  notification: NotificationCreateNestedOneWithoutOrderNotificationInput;
};

export type OrderNotificationCreateOrConnectWithoutOrderInput = {
  where: OrderNotificationWhereUniqueInput;
  create: OrderNotificationCreateWithoutOrderInput;
};

export type OrderNotificationUpsertWithWhereUniqueWithoutOrderInput = {
  where: OrderNotificationWhereUniqueInput;
  update: OrderNotificationUpdateWithoutOrderInput;
  create: OrderNotificationCreateWithoutOrderInput;
};

export type OrderNotificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderNotificationUpdateWithWhereUniqueWithoutOrderInput = {
  where: OrderNotificationWhereUniqueInput;
  data: OrderNotificationUpdateWithoutOrderInput;
};

export type OrderNotificationUpdateManyWithWhereWithoutOrderInput = {
  where: OrderNotificationScalarWhereInput;
  data: OrderNotificationUpdateManyMutationInput;
};

export type OrderNotificationScalarWhereInput = {
  AND?: Maybe<Array<OrderNotificationScalarWhereInput>>;
  OR?: Maybe<Array<OrderNotificationScalarWhereInput>>;
  NOT?: Maybe<Array<OrderNotificationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  orderId?: Maybe<IntNullableFilter>;
  notificationId?: Maybe<StringFilter>;
};

export type CartItemCreateWithoutOrderInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  item: ItemCreateNestedOneWithoutCartItemsInput;
  user?: Maybe<UserCreateNestedOneWithoutCartItemsInput>;
};

export type CartItemCreateOrConnectWithoutOrderInput = {
  where: CartItemWhereUniqueInput;
  create: CartItemCreateWithoutOrderInput;
};

export type CartItemUpsertWithWhereUniqueWithoutOrderInput = {
  where: CartItemWhereUniqueInput;
  update: CartItemUpdateWithoutOrderInput;
  create: CartItemCreateWithoutOrderInput;
};

export type CartItemUpdateWithWhereUniqueWithoutOrderInput = {
  where: CartItemWhereUniqueInput;
  data: CartItemUpdateWithoutOrderInput;
};

export type CartItemUpdateManyWithWhereWithoutOrderInput = {
  where: CartItemScalarWhereInput;
  data: CartItemUpdateManyMutationInput;
};

export type NestedEnumParameterKeyFilter = {
  equals?: Maybe<ParameterKey>;
  in?: Maybe<Array<ParameterKey>>;
  notIn?: Maybe<Array<ParameterKey>>;
  not?: Maybe<NestedEnumParameterKeyFilter>;
};

export type NestedEnumParameterTypeFilter = {
  equals?: Maybe<ParameterType>;
  in?: Maybe<Array<ParameterType>>;
  notIn?: Maybe<Array<ParameterType>>;
  not?: Maybe<NestedEnumParameterTypeFilter>;
};

export type ItemCreateNestedOneWithoutImageFileInput = {
  create?: Maybe<ItemCreateWithoutImageFileInput>;
  connectOrCreate?: Maybe<ItemCreateOrConnectWithoutImageFileInput>;
  connect?: Maybe<ItemWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutDocFileInput = {
  create?: Maybe<UserCreateWithoutDocFileInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutDocFileInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutImageFileInput = {
  create?: Maybe<UserCreateWithoutImageFileInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutImageFileInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type ItemUpdateOneWithoutImageFileInput = {
  create?: Maybe<ItemCreateWithoutImageFileInput>;
  connectOrCreate?: Maybe<ItemCreateOrConnectWithoutImageFileInput>;
  upsert?: Maybe<ItemUpsertWithoutImageFileInput>;
  connect?: Maybe<ItemWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ItemUpdateWithoutImageFileInput>;
};

export type UserUpdateOneWithoutDocFileInput = {
  create?: Maybe<UserCreateWithoutDocFileInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutDocFileInput>;
  upsert?: Maybe<UserUpsertWithoutDocFileInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutDocFileInput>;
};

export type UserUpdateOneWithoutImageFileInput = {
  create?: Maybe<UserCreateWithoutImageFileInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutImageFileInput>;
  upsert?: Maybe<UserUpsertWithoutImageFileInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutImageFileInput>;
};

export type ItemUpdateWithoutCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  group?: Maybe<GroupUpdateOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeUpdateOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerUpdateOneWithoutItemsInput>;
  unit?: Maybe<UnitUpdateOneWithoutItemsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutItemInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutItemInput>;
};

export type ItemUpdateWithoutGroupInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutItemsInput>;
  plantType?: Maybe<PlantTypeUpdateOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerUpdateOneWithoutItemsInput>;
  unit?: Maybe<UnitUpdateOneWithoutItemsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutItemInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutItemInput>;
};

export type CategoryCreateNestedOneWithoutImageFileInput = {
  create?: Maybe<CategoryCreateWithoutImageFileInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutImageFileInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
};

export type CategoryUpdateOneWithoutImageFileInput = {
  create?: Maybe<CategoryCreateWithoutImageFileInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutImageFileInput>;
  upsert?: Maybe<CategoryUpsertWithoutImageFileInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CategoryUpdateWithoutImageFileInput>;
};

export type CartItemUpdateWithoutItemInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCartItemsInput>;
  order?: Maybe<OrderUpdateOneWithoutCartItemsInput>;
};

export type ItemUpdateWithoutManufacturerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutItemsInput>;
  group?: Maybe<GroupUpdateOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeUpdateOneWithoutItemsInput>;
  unit?: Maybe<UnitUpdateOneWithoutItemsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutItemInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutItemInput>;
};

export type ItemUpdateWithoutPlantTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutItemsInput>;
  group?: Maybe<GroupUpdateOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerUpdateOneWithoutItemsInput>;
  unit?: Maybe<UnitUpdateOneWithoutItemsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutItemInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutItemInput>;
};

export type ItemUpdateWithoutUnitInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutItemsInput>;
  group?: Maybe<GroupUpdateOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeUpdateOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerUpdateOneWithoutItemsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutItemInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutItemInput>;
};

export type DeviceTokenUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
};

export type DeviceTokenTokenUserIdCompoundUniqueInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type DeviceTokenUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
  item?: Maybe<ItemUpdateOneRequiredWithoutCartItemsInput>;
  order?: Maybe<OrderUpdateOneWithoutCartItemsInput>;
};

export type OrderUpdateWithoutUserSubmittedInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userTaken?: Maybe<UserUpdateOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserUpdateOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressUpdateOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressUpdateOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationUpdateManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutOrderInput>;
};

export type OrderUpdateWithoutUserTakenInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userSubmitted?: Maybe<UserUpdateOneRequiredWithoutOrdersSubmittedInput>;
  userCancelled?: Maybe<UserUpdateOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressUpdateOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressUpdateOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationUpdateManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutOrderInput>;
};

export type OrderUpdateWithoutUserCancelledInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userSubmitted?: Maybe<UserUpdateOneRequiredWithoutOrdersSubmittedInput>;
  userTaken?: Maybe<UserUpdateOneWithoutOrdersTakenInput>;
  address?: Maybe<AddressUpdateOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressUpdateOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationUpdateManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutOrderInput>;
};

export type OrderNotificationCreateNestedOneWithoutNotificationInput = {
  create?: Maybe<OrderNotificationCreateWithoutNotificationInput>;
  connectOrCreate?: Maybe<OrderNotificationCreateOrConnectWithoutNotificationInput>;
  connect?: Maybe<OrderNotificationWhereUniqueInput>;
};

export type NotificationUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  orderNotification?: Maybe<OrderNotificationUpdateOneWithoutNotificationInput>;
};

export type NotificationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumNotificationTypeFieldUpdateOperationsInput>;
};

export type EnumNotificationTypeFilter = {
  equals?: Maybe<NotificationType>;
  in?: Maybe<Array<NotificationType>>;
  notIn?: Maybe<Array<NotificationType>>;
  not?: Maybe<NestedEnumNotificationTypeFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type OrderCreateNestedOneWithoutDriverAddressInput = {
  create?: Maybe<OrderCreateWithoutDriverAddressInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutDriverAddressInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type OrderUpdateOneWithoutDriverAddressInput = {
  create?: Maybe<OrderCreateWithoutDriverAddressInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutDriverAddressInput>;
  upsert?: Maybe<OrderUpsertWithoutDriverAddressInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderUpdateWithoutDriverAddressInput>;
};

export type OrderCreateNestedOneWithoutAddressInput = {
  create?: Maybe<OrderCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutAddressInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
};

export type OrderUpdateOneWithoutAddressInput = {
  create?: Maybe<OrderCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutAddressInput>;
  upsert?: Maybe<OrderUpsertWithoutAddressInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderUpdateWithoutAddressInput>;
};

export type NotificationCreateNestedOneWithoutOrderNotificationInput = {
  create?: Maybe<NotificationCreateWithoutOrderNotificationInput>;
  connectOrCreate?: Maybe<NotificationCreateOrConnectWithoutOrderNotificationInput>;
  connect?: Maybe<NotificationWhereUniqueInput>;
};

export type OrderNotificationUpdateWithoutOrderInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  notification?: Maybe<NotificationUpdateOneRequiredWithoutOrderNotificationInput>;
};

export type OrderNotificationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutOrderInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
  item?: Maybe<ItemUpdateOneRequiredWithoutCartItemsInput>;
  user?: Maybe<UserUpdateOneWithoutCartItemsInput>;
};

export type ItemCreateWithoutImageFileInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  ouncesPerUnit?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutItemsInput;
  group?: Maybe<GroupCreateNestedOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeCreateNestedOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerCreateNestedOneWithoutItemsInput>;
  unit?: Maybe<UnitCreateNestedOneWithoutItemsInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutItemInput>;
};

export type ItemCreateOrConnectWithoutImageFileInput = {
  where: ItemWhereUniqueInput;
  create: ItemCreateWithoutImageFileInput;
};

export type UserCreateWithoutDocFileInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  imageFile?: Maybe<FileCreateNestedOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderCreateNestedManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderCreateNestedManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderCreateNestedManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationCreateNestedManyWithoutUserInput>;
};

export type UserCreateOrConnectWithoutDocFileInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutDocFileInput;
};

export type UserCreateWithoutImageFileInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  docFile?: Maybe<FileCreateNestedOneWithoutUserDocumentInput>;
  deviceTokens?: Maybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderCreateNestedManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderCreateNestedManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderCreateNestedManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationCreateNestedManyWithoutUserInput>;
};

export type UserCreateOrConnectWithoutImageFileInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutImageFileInput;
};

export type ItemUpsertWithoutImageFileInput = {
  update: ItemUpdateWithoutImageFileInput;
  create: ItemCreateWithoutImageFileInput;
};

export type ItemUpdateWithoutImageFileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  ouncesPerUnit?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutItemsInput>;
  group?: Maybe<GroupUpdateOneWithoutItemsInput>;
  plantType?: Maybe<PlantTypeUpdateOneWithoutItemsInput>;
  manufacturer?: Maybe<ManufacturerUpdateOneWithoutItemsInput>;
  unit?: Maybe<UnitUpdateOneWithoutItemsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutItemInput>;
};

export type UserUpsertWithoutDocFileInput = {
  update: UserUpdateWithoutDocFileInput;
  create: UserCreateWithoutDocFileInput;
};

export type UserUpdateWithoutDocFileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  imageFile?: Maybe<FileUpdateOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenUpdateManyWithoutUserInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderUpdateManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderUpdateManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderUpdateManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
};

export type UserUpsertWithoutImageFileInput = {
  update: UserUpdateWithoutImageFileInput;
  create: UserCreateWithoutImageFileInput;
};

export type UserUpdateWithoutImageFileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  docFile?: Maybe<FileUpdateOneWithoutUserDocumentInput>;
  deviceTokens?: Maybe<DeviceTokenUpdateManyWithoutUserInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderUpdateManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderUpdateManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderUpdateManyWithoutUserCancelledInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
};

export type CategoryCreateWithoutImageFileInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  items?: Maybe<ItemCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateOrConnectWithoutImageFileInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutImageFileInput;
};

export type CategoryUpsertWithoutImageFileInput = {
  update: CategoryUpdateWithoutImageFileInput;
  create: CategoryCreateWithoutImageFileInput;
};

export type CategoryUpdateWithoutImageFileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<ItemUpdateManyWithoutCategoryInput>;
};

export type OrderNotificationCreateWithoutNotificationInput = {
  id?: Maybe<Scalars['String']>;
  order?: Maybe<OrderCreateNestedOneWithoutNotificationsInput>;
};

export type OrderNotificationCreateOrConnectWithoutNotificationInput = {
  where: OrderNotificationWhereUniqueInput;
  create: OrderNotificationCreateWithoutNotificationInput;
};

export type EnumNotificationTypeFieldUpdateOperationsInput = {
  set?: Maybe<NotificationType>;
};

export type OrderNotificationUpdateOneWithoutNotificationInput = {
  create?: Maybe<OrderNotificationCreateWithoutNotificationInput>;
  connectOrCreate?: Maybe<OrderNotificationCreateOrConnectWithoutNotificationInput>;
  upsert?: Maybe<OrderNotificationUpsertWithoutNotificationInput>;
  connect?: Maybe<OrderNotificationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderNotificationUpdateWithoutNotificationInput>;
};

export type NestedEnumNotificationTypeFilter = {
  equals?: Maybe<NotificationType>;
  in?: Maybe<Array<NotificationType>>;
  notIn?: Maybe<Array<NotificationType>>;
  not?: Maybe<NestedEnumNotificationTypeFilter>;
};

export type OrderCreateWithoutDriverAddressInput = {
  beatifiedId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<Scalars['String']>;
  userSubmitted: UserCreateNestedOneWithoutOrdersSubmittedInput;
  userTaken?: Maybe<UserCreateNestedOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserCreateNestedOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressCreateNestedOneWithoutOrderInput>;
  notifications?: Maybe<OrderNotificationCreateNestedManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutOrderInput>;
};

export type OrderCreateOrConnectWithoutDriverAddressInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutDriverAddressInput;
};

export type OrderUpsertWithoutDriverAddressInput = {
  update: OrderUpdateWithoutDriverAddressInput;
  create: OrderCreateWithoutDriverAddressInput;
};

export type OrderUpdateWithoutDriverAddressInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userSubmitted?: Maybe<UserUpdateOneRequiredWithoutOrdersSubmittedInput>;
  userTaken?: Maybe<UserUpdateOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserUpdateOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressUpdateOneWithoutOrderInput>;
  notifications?: Maybe<OrderNotificationUpdateManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutOrderInput>;
};

export type OrderCreateWithoutAddressInput = {
  beatifiedId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<Scalars['String']>;
  userSubmitted: UserCreateNestedOneWithoutOrdersSubmittedInput;
  userTaken?: Maybe<UserCreateNestedOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserCreateNestedOneWithoutOrdersCancelledInput>;
  driverAddress?: Maybe<AddressCreateNestedOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationCreateNestedManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutOrderInput>;
};

export type OrderCreateOrConnectWithoutAddressInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutAddressInput;
};

export type OrderUpsertWithoutAddressInput = {
  update: OrderUpdateWithoutAddressInput;
  create: OrderCreateWithoutAddressInput;
};

export type OrderUpdateWithoutAddressInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userSubmitted?: Maybe<UserUpdateOneRequiredWithoutOrdersSubmittedInput>;
  userTaken?: Maybe<UserUpdateOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserUpdateOneWithoutOrdersCancelledInput>;
  driverAddress?: Maybe<AddressUpdateOneWithoutDriverOrderInput>;
  notifications?: Maybe<OrderNotificationUpdateManyWithoutOrderInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutOrderInput>;
};

export type NotificationCreateWithoutOrderNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: NotificationType;
  user: UserCreateNestedOneWithoutNotificationsInput;
};

export type NotificationCreateOrConnectWithoutOrderNotificationInput = {
  where: NotificationWhereUniqueInput;
  create: NotificationCreateWithoutOrderNotificationInput;
};

export type NotificationUpdateOneRequiredWithoutOrderNotificationInput = {
  create?: Maybe<NotificationCreateWithoutOrderNotificationInput>;
  connectOrCreate?: Maybe<NotificationCreateOrConnectWithoutOrderNotificationInput>;
  upsert?: Maybe<NotificationUpsertWithoutOrderNotificationInput>;
  connect?: Maybe<NotificationWhereUniqueInput>;
  update?: Maybe<NotificationUpdateWithoutOrderNotificationInput>;
};

export type OrderCreateNestedOneWithoutNotificationsInput = {
  create?: Maybe<OrderCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutNotificationsInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
};

export type OrderNotificationUpsertWithoutNotificationInput = {
  update: OrderNotificationUpdateWithoutNotificationInput;
  create: OrderNotificationCreateWithoutNotificationInput;
};

export type OrderNotificationUpdateWithoutNotificationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutNotificationsInput>;
};

export type UserCreateNestedOneWithoutNotificationsInput = {
  create?: Maybe<UserCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutNotificationsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type NotificationUpsertWithoutOrderNotificationInput = {
  update: NotificationUpdateWithoutOrderNotificationInput;
  create: NotificationCreateWithoutOrderNotificationInput;
};

export type NotificationUpdateWithoutOrderNotificationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutNotificationsInput>;
};

export type OrderCreateWithoutNotificationsInput = {
  beatifiedId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<Scalars['String']>;
  userSubmitted: UserCreateNestedOneWithoutOrdersSubmittedInput;
  userTaken?: Maybe<UserCreateNestedOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserCreateNestedOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressCreateNestedOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressCreateNestedOneWithoutDriverOrderInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutOrderInput>;
};

export type OrderCreateOrConnectWithoutNotificationsInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutNotificationsInput;
};

export type OrderUpdateOneWithoutNotificationsInput = {
  create?: Maybe<OrderCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutNotificationsInput>;
  upsert?: Maybe<OrderUpsertWithoutNotificationsInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderUpdateWithoutNotificationsInput>;
};

export type UserCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  viewedNotificationsAt?: Maybe<Scalars['DateTime']>;
  docFile?: Maybe<FileCreateNestedOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileCreateNestedOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenCreateNestedManyWithoutUserInput>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderCreateNestedManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderCreateNestedManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderCreateNestedManyWithoutUserCancelledInput>;
};

export type UserCreateOrConnectWithoutNotificationsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutNotificationsInput;
};

export type UserUpdateOneRequiredWithoutNotificationsInput = {
  create?: Maybe<UserCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutNotificationsInput>;
  upsert?: Maybe<UserUpsertWithoutNotificationsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutNotificationsInput>;
};

export type OrderUpsertWithoutNotificationsInput = {
  update: OrderUpdateWithoutNotificationsInput;
  create: OrderCreateWithoutNotificationsInput;
};

export type OrderUpdateWithoutNotificationsInput = {
  beatifiedId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  takenAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  eta?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passenger?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userSubmitted?: Maybe<UserUpdateOneRequiredWithoutOrdersSubmittedInput>;
  userTaken?: Maybe<UserUpdateOneWithoutOrdersTakenInput>;
  userCancelled?: Maybe<UserUpdateOneWithoutOrdersCancelledInput>;
  address?: Maybe<AddressUpdateOneWithoutOrderInput>;
  driverAddress?: Maybe<AddressUpdateOneWithoutDriverOrderInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutOrderInput>;
};

export type UserUpsertWithoutNotificationsInput = {
  update: UserUpdateWithoutNotificationsInput;
  create: UserCreateWithoutNotificationsInput;
};

export type UserUpdateWithoutNotificationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  identified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  viewedNotificationsAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  docFile?: Maybe<FileUpdateOneWithoutUserDocumentInput>;
  imageFile?: Maybe<FileUpdateOneWithoutUserImageInput>;
  deviceTokens?: Maybe<DeviceTokenUpdateManyWithoutUserInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutUserInput>;
  ordersSubmitted?: Maybe<OrderUpdateManyWithoutUserSubmittedInput>;
  ordersTaken?: Maybe<OrderUpdateManyWithoutUserTakenInput>;
  ordersCancelled?: Maybe<OrderUpdateManyWithoutUserCancelledInput>;
};

export type Query = {
  __typename?: 'Query';
  categories: Array<Category>;
  adminCategory?: Maybe<Category>;
  adminCategories: Array<Category>;
  adminCategoriesCount?: Maybe<Scalars['Int']>;
  groups: Array<Group>;
  adminGroup?: Maybe<Group>;
  adminGroups: Array<Group>;
  adminGroupsCount?: Maybe<Scalars['Int']>;
  item?: Maybe<Item>;
  items: Array<Item>;
  adminItem?: Maybe<Item>;
  adminItems: Array<Item>;
  adminItemsCount?: Maybe<Scalars['Int']>;
  manufacturers: Array<Manufacturer>;
  adminManufacturer?: Maybe<Manufacturer>;
  adminManufacturers: Array<Manufacturer>;
  adminManufacturersCount?: Maybe<Scalars['Int']>;
  plantTypes: Array<PlantType>;
  adminPlantType?: Maybe<PlantType>;
  adminPlantTypes: Array<PlantType>;
  adminPlantTypesCount?: Maybe<Scalars['Int']>;
  units: Array<Unit>;
  adminUnit?: Maybe<Unit>;
  adminUnits: Array<Unit>;
  adminUnitsCount?: Maybe<Scalars['Int']>;
  user: User;
  adminUser?: Maybe<User>;
  adminUsers: Array<User>;
  adminUsersCount?: Maybe<Scalars['Int']>;
  cartItems: Array<CartItem>;
  adminCartItem?: Maybe<CartItem>;
  adminCartItems: Array<CartItem>;
  adminCartItemsCount?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  adminOrder?: Maybe<Order>;
  adminOrders: Array<Order>;
  adminOrdersCount?: Maybe<Scalars['Int']>;
  parameter?: Maybe<Parameter>;
  adminParameter?: Maybe<Parameter>;
  adminParameters: Array<Parameter>;
  adminParametersCount?: Maybe<Scalars['Int']>;
};


export type QueryCategoriesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
};


export type QueryAdminCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryAdminCategoriesArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
};


export type QueryAdminCategoriesCountArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<CategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryGroupsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<GroupWhereUniqueInput>;
};


export type QueryAdminGroupArgs = {
  where: GroupWhereUniqueInput;
};


export type QueryAdminGroupsArgs = {
  where?: Maybe<GroupWhereInput>;
  orderBy?: Maybe<Array<GroupOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<GroupWhereUniqueInput>;
};


export type QueryAdminGroupsCountArgs = {
  where?: Maybe<GroupWhereInput>;
  orderBy?: Maybe<GroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<GroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryItemArgs = {
  where: ItemWhereUniqueInput;
};


export type QueryItemsArgs = {
  where?: Maybe<QueryItemsWhereInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ItemWhereUniqueInput>;
};


export type QueryAdminItemArgs = {
  where: ItemWhereUniqueInput;
};


export type QueryAdminItemsArgs = {
  where?: Maybe<ItemWhereInput>;
  orderBy?: Maybe<Array<ItemOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ItemWhereUniqueInput>;
};


export type QueryAdminItemsCountArgs = {
  where?: Maybe<ItemWhereInput>;
  orderBy?: Maybe<ItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryManufacturersArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ManufacturerWhereUniqueInput>;
};


export type QueryAdminManufacturerArgs = {
  where: ManufacturerWhereUniqueInput;
};


export type QueryAdminManufacturersArgs = {
  where?: Maybe<ManufacturerWhereInput>;
  orderBy?: Maybe<Array<ManufacturerOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ManufacturerWhereUniqueInput>;
};


export type QueryAdminManufacturersCountArgs = {
  where?: Maybe<ManufacturerWhereInput>;
  orderBy?: Maybe<ManufacturerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ManufacturerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPlantTypesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PlantTypeWhereUniqueInput>;
};


export type QueryAdminPlantTypeArgs = {
  where: PlantTypeWhereUniqueInput;
};


export type QueryAdminPlantTypesArgs = {
  where?: Maybe<PlantTypeWhereInput>;
  orderBy?: Maybe<Array<PlantTypeOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PlantTypeWhereUniqueInput>;
};


export type QueryAdminPlantTypesCountArgs = {
  where?: Maybe<PlantTypeWhereInput>;
  orderBy?: Maybe<PlantTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PlantTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryUnitsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UnitWhereUniqueInput>;
};


export type QueryAdminUnitArgs = {
  where: UnitWhereUniqueInput;
};


export type QueryAdminUnitsArgs = {
  where?: Maybe<UnitWhereInput>;
  orderBy?: Maybe<Array<UnitOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UnitWhereUniqueInput>;
};


export type QueryAdminUnitsCountArgs = {
  where?: Maybe<UnitWhereInput>;
  orderBy?: Maybe<UnitOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UnitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryAdminUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UserWhereUniqueInput>;
};


export type QueryAdminUsersCountArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryCartItemsArgs = {
  where?: Maybe<QueryCartItemsWhereInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CartItemWhereUniqueInput>;
};


export type QueryAdminCartItemArgs = {
  where: CartItemWhereUniqueInput;
};


export type QueryAdminCartItemsArgs = {
  where?: Maybe<CartItemWhereInput>;
  orderBy?: Maybe<Array<CartItemOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CartItemWhereUniqueInput>;
};


export type QueryAdminCartItemsCountArgs = {
  where?: Maybe<CartItemWhereInput>;
  orderBy?: Maybe<CartItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CartItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<OrderWhereUniqueInput>;
};


export type QueryAdminOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryAdminOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<OrderWhereUniqueInput>;
};


export type QueryAdminOrdersCountArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryParameterArgs = {
  where: ParameterWhereUniqueInput;
};


export type QueryAdminParameterArgs = {
  where: ParameterWhereUniqueInput;
};


export type QueryAdminParametersArgs = {
  where?: Maybe<ParameterWhereInput>;
  orderBy?: Maybe<Array<ParameterOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ParameterWhereUniqueInput>;
};


export type QueryAdminParametersCountArgs = {
  where?: Maybe<ParameterWhereInput>;
  orderBy?: Maybe<ParameterOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ParameterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminCreateOneCategory: Category;
  adminUpdateOneCategory?: Maybe<Category>;
  adminUpdateManyCategory: AffectedRowsOutput;
  adminUpsertOneCategory: Category;
  adminDeleteOneCategory?: Maybe<Category>;
  adminDeleteManyCategory: AffectedRowsOutput;
  adminCreateOneGroup: Group;
  adminUpdateOneGroup?: Maybe<Group>;
  adminUpdateManyGroup: AffectedRowsOutput;
  adminUpsertOneGroup: Group;
  adminDeleteOneGroup?: Maybe<Group>;
  adminDeleteManyGroup: AffectedRowsOutput;
  adminCreateOneItem: Item;
  adminUpdateOneItem?: Maybe<Item>;
  adminUpdateManyItem: AffectedRowsOutput;
  adminUpsertOneItem: Item;
  adminDeleteOneItem?: Maybe<Item>;
  adminDeleteManyItem: AffectedRowsOutput;
  adminCreateOneManufacturer: Manufacturer;
  adminUpdateOneManufacturer?: Maybe<Manufacturer>;
  adminUpdateManyManufacturer: AffectedRowsOutput;
  adminUpsertOneManufacturer: Manufacturer;
  adminDeleteOneManufacturer?: Maybe<Manufacturer>;
  adminDeleteManyManufacturer: AffectedRowsOutput;
  adminCreateOnePlantType: PlantType;
  adminUpdateOnePlantType?: Maybe<PlantType>;
  adminUpdateManyPlantType: AffectedRowsOutput;
  adminUpsertOnePlantType: PlantType;
  adminDeleteOnePlantType?: Maybe<PlantType>;
  adminDeleteManyPlantType: AffectedRowsOutput;
  adminCreateOneUnit: Unit;
  adminUpdateOneUnit?: Maybe<Unit>;
  adminUpdateManyUnit: AffectedRowsOutput;
  adminUpsertOneUnit: Unit;
  adminDeleteOneUnit?: Maybe<Unit>;
  adminDeleteManyUnit: AffectedRowsOutput;
  viewNotifications: User;
  updateUser?: Maybe<User>;
  connectFileToUser?: Maybe<User>;
  identifyUser?: Maybe<IdentifyUserResult>;
  adminCreateOneUser: User;
  adminUpdateOneUser?: Maybe<User>;
  adminUpdateManyUser: AffectedRowsOutput;
  adminUpsertOneUser: User;
  adminDeleteOneUser?: Maybe<User>;
  adminDeleteManyUser: AffectedRowsOutput;
  addItemToCart?: Maybe<AddItemToCartResult>;
  removeItemFromCart?: Maybe<CartItem>;
  adminCreateOneCartItem: CartItem;
  adminUpdateOneCartItem?: Maybe<CartItem>;
  adminUpdateManyCartItem: AffectedRowsOutput;
  adminUpsertOneCartItem: CartItem;
  adminDeleteOneCartItem?: Maybe<CartItem>;
  adminDeleteManyCartItem: AffectedRowsOutput;
  takeOrder?: Maybe<Order>;
  completeOrder?: Maybe<Order>;
  cancelOrder?: Maybe<Order>;
  createOrder: Order;
  adminCreateOneOrder: Order;
  adminUpdateOneOrder?: Maybe<Order>;
  adminUpdateManyOrder: AffectedRowsOutput;
  adminUpsertOneOrder: Order;
  adminDeleteOneOrder?: Maybe<Order>;
  adminDeleteManyOrder: AffectedRowsOutput;
  registerDeviceToken?: Maybe<DeviceToken>;
  unregisterDeviceToken: DeviceToken;
  adminCreateOneParameter: Parameter;
  adminUpdateOneParameter?: Maybe<Parameter>;
  adminUpdateManyParameter: AffectedRowsOutput;
  adminUpsertOneParameter: Parameter;
  adminDeleteOneParameter?: Maybe<Parameter>;
  adminDeleteManyParameter: AffectedRowsOutput;
  uploadFile: FilePayload;
};


export type MutationAdminCreateOneCategoryArgs = {
  data: CategoryCreateInput;
};


export type MutationAdminUpdateOneCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationAdminUpdateManyCategoryArgs = {
  data: CategoryUpdateManyMutationInput;
  where?: Maybe<CategoryWhereInput>;
};


export type MutationAdminUpsertOneCategoryArgs = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
};


export type MutationAdminDeleteOneCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type MutationAdminDeleteManyCategoryArgs = {
  where?: Maybe<CategoryWhereInput>;
};


export type MutationAdminCreateOneGroupArgs = {
  data: GroupCreateInput;
};


export type MutationAdminUpdateOneGroupArgs = {
  data: GroupUpdateInput;
  where: GroupWhereUniqueInput;
};


export type MutationAdminUpdateManyGroupArgs = {
  data: GroupUpdateManyMutationInput;
  where?: Maybe<GroupWhereInput>;
};


export type MutationAdminUpsertOneGroupArgs = {
  where: GroupWhereUniqueInput;
  create: GroupCreateInput;
  update: GroupUpdateInput;
};


export type MutationAdminDeleteOneGroupArgs = {
  where: GroupWhereUniqueInput;
};


export type MutationAdminDeleteManyGroupArgs = {
  where?: Maybe<GroupWhereInput>;
};


export type MutationAdminCreateOneItemArgs = {
  data: ItemCreateInput;
};


export type MutationAdminUpdateOneItemArgs = {
  data: ItemUpdateInput;
  where: ItemWhereUniqueInput;
};


export type MutationAdminUpdateManyItemArgs = {
  data: ItemUpdateManyMutationInput;
  where?: Maybe<ItemWhereInput>;
};


export type MutationAdminUpsertOneItemArgs = {
  where: ItemWhereUniqueInput;
  create: ItemCreateInput;
  update: ItemUpdateInput;
};


export type MutationAdminDeleteOneItemArgs = {
  where: ItemWhereUniqueInput;
};


export type MutationAdminDeleteManyItemArgs = {
  where?: Maybe<ItemWhereInput>;
};


export type MutationAdminCreateOneManufacturerArgs = {
  data: ManufacturerCreateInput;
};


export type MutationAdminUpdateOneManufacturerArgs = {
  data: ManufacturerUpdateInput;
  where: ManufacturerWhereUniqueInput;
};


export type MutationAdminUpdateManyManufacturerArgs = {
  data: ManufacturerUpdateManyMutationInput;
  where?: Maybe<ManufacturerWhereInput>;
};


export type MutationAdminUpsertOneManufacturerArgs = {
  where: ManufacturerWhereUniqueInput;
  create: ManufacturerCreateInput;
  update: ManufacturerUpdateInput;
};


export type MutationAdminDeleteOneManufacturerArgs = {
  where: ManufacturerWhereUniqueInput;
};


export type MutationAdminDeleteManyManufacturerArgs = {
  where?: Maybe<ManufacturerWhereInput>;
};


export type MutationAdminCreateOnePlantTypeArgs = {
  data: PlantTypeCreateInput;
};


export type MutationAdminUpdateOnePlantTypeArgs = {
  data: PlantTypeUpdateInput;
  where: PlantTypeWhereUniqueInput;
};


export type MutationAdminUpdateManyPlantTypeArgs = {
  data: PlantTypeUpdateManyMutationInput;
  where?: Maybe<PlantTypeWhereInput>;
};


export type MutationAdminUpsertOnePlantTypeArgs = {
  where: PlantTypeWhereUniqueInput;
  create: PlantTypeCreateInput;
  update: PlantTypeUpdateInput;
};


export type MutationAdminDeleteOnePlantTypeArgs = {
  where: PlantTypeWhereUniqueInput;
};


export type MutationAdminDeleteManyPlantTypeArgs = {
  where?: Maybe<PlantTypeWhereInput>;
};


export type MutationAdminCreateOneUnitArgs = {
  data: UnitCreateInput;
};


export type MutationAdminUpdateOneUnitArgs = {
  data: UnitUpdateInput;
  where: UnitWhereUniqueInput;
};


export type MutationAdminUpdateManyUnitArgs = {
  data: UnitUpdateManyMutationInput;
  where?: Maybe<UnitWhereInput>;
};


export type MutationAdminUpsertOneUnitArgs = {
  where: UnitWhereUniqueInput;
  create: UnitCreateInput;
  update: UnitUpdateInput;
};


export type MutationAdminDeleteOneUnitArgs = {
  where: UnitWhereUniqueInput;
};


export type MutationAdminDeleteManyUnitArgs = {
  where?: Maybe<UnitWhereInput>;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationConnectFileToUserArgs = {
  userId: Scalars['ID'];
  fileId?: Maybe<Scalars['ID']>;
  docId?: Maybe<Scalars['ID']>;
};


export type MutationIdentifyUserArgs = {
  userId: Scalars['ID'];
  docUrl: Scalars['String'];
};


export type MutationAdminCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationAdminUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationAdminUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};


export type MutationAdminUpsertOneUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};


export type MutationAdminDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationAdminDeleteManyUserArgs = {
  where?: Maybe<UserWhereInput>;
};


export type MutationAddItemToCartArgs = {
  userId: Scalars['ID'];
  itemId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
};


export type MutationRemoveItemFromCartArgs = {
  userId: Scalars['ID'];
  itemId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
};


export type MutationAdminCreateOneCartItemArgs = {
  data: CartItemCreateInput;
};


export type MutationAdminUpdateOneCartItemArgs = {
  data: CartItemUpdateInput;
  where: CartItemWhereUniqueInput;
};


export type MutationAdminUpdateManyCartItemArgs = {
  data: CartItemUpdateManyMutationInput;
  where?: Maybe<CartItemWhereInput>;
};


export type MutationAdminUpsertOneCartItemArgs = {
  where: CartItemWhereUniqueInput;
  create: CartItemCreateInput;
  update: CartItemUpdateInput;
};


export type MutationAdminDeleteOneCartItemArgs = {
  where: CartItemWhereUniqueInput;
};


export type MutationAdminDeleteManyCartItemArgs = {
  where?: Maybe<CartItemWhereInput>;
};


export type MutationTakeOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationCompleteOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationCancelOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};


export type MutationAdminCreateOneOrderArgs = {
  data: OrderCreateInput;
};


export type MutationAdminUpdateOneOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationAdminUpdateManyOrderArgs = {
  data: OrderUpdateManyMutationInput;
  where?: Maybe<OrderWhereInput>;
};


export type MutationAdminUpsertOneOrderArgs = {
  where: OrderWhereUniqueInput;
  create: OrderCreateInput;
  update: OrderUpdateInput;
};


export type MutationAdminDeleteOneOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationAdminDeleteManyOrderArgs = {
  where?: Maybe<OrderWhereInput>;
};


export type MutationRegisterDeviceTokenArgs = {
  token: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUnregisterDeviceTokenArgs = {
  id: Scalars['String'];
};


export type MutationAdminCreateOneParameterArgs = {
  data: ParameterCreateInput;
};


export type MutationAdminUpdateOneParameterArgs = {
  data: ParameterUpdateInput;
  where: ParameterWhereUniqueInput;
};


export type MutationAdminUpdateManyParameterArgs = {
  data: ParameterUpdateManyMutationInput;
  where?: Maybe<ParameterWhereInput>;
};


export type MutationAdminUpsertOneParameterArgs = {
  where: ParameterWhereUniqueInput;
  create: ParameterCreateInput;
  update: ParameterUpdateInput;
};


export type MutationAdminDeleteOneParameterArgs = {
  where: ParameterWhereUniqueInput;
};


export type MutationAdminDeleteManyParameterArgs = {
  where?: Maybe<ParameterWhereInput>;
};


export type MutationUploadFileArgs = {
  name: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  orderCreated?: Maybe<Order>;
  orderUpdated?: Maybe<Order>;
};

export type UploadFileMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile: { __typename?: 'FilePayload', url: string, file: { __typename?: 'File', id: string } } };

export type OrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: number }> };

export type ParameterQueryVariables = Exact<{
  where: ParameterWhereUniqueInput;
}>;


export type ParameterQuery = { __typename?: 'Query', parameter?: Maybe<{ __typename?: 'Parameter', id: string, key: ParameterKey, type: ParameterType, name: string, value: number }> };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, uuid?: Maybe<string>, email?: Maybe<string>, phoneNumber?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, role: Role } };


export const UploadFileDocument = gql`
    mutation uploadFile($name: String!) {
  uploadFile(name: $name) {
    file {
      id
    }
    url
  }
}
    `;
export type UploadFileMutationFn = ApolloReactCommon.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const OrdersDocument = gql`
    query orders {
  orders {
    id
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = ApolloReactCommon.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const ParameterDocument = gql`
    query parameter($where: ParameterWhereUniqueInput!) {
  parameter(where: $where) {
    id
    key
    type
    name
    value
  }
}
    `;

/**
 * __useParameterQuery__
 *
 * To run a query within a React component, call `useParameterQuery` and pass it any options that fit your needs.
 * When your component renders, `useParameterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParameterQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useParameterQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ParameterQuery, ParameterQueryVariables>) {
        return ApolloReactHooks.useQuery<ParameterQuery, ParameterQueryVariables>(ParameterDocument, baseOptions);
      }
export function useParameterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParameterQuery, ParameterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParameterQuery, ParameterQueryVariables>(ParameterDocument, baseOptions);
        }
export type ParameterQueryHookResult = ReturnType<typeof useParameterQuery>;
export type ParameterLazyQueryHookResult = ReturnType<typeof useParameterLazyQuery>;
export type ParameterQueryResult = ApolloReactCommon.QueryResult<ParameterQuery, ParameterQueryVariables>;
export const UserDocument = gql`
    query user {
  user {
    id
    uuid
    email
    phoneNumber
    firstName
    lastName
    role
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;