import React from "react";
import {
  DateField,
  FunctionField,
  ReferenceField,
  Show as RAShow,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import OrderTitle from "../../components/OrderTitle";
import { EMPTY_TEXT } from "../../theme/constants";
import { renderUserField } from "../../utils/common";

const Show: React.FC<ShowProps> = (props) => (
  <RAShow {...props} title={<OrderTitle />}>
    <SimpleShowLayout>
      <DateField showTime source="submittedAt" />
      <ReferenceField
        source="userSubmitted"
        reference="User"
        label="Submitted by"
      >
        <FunctionField render={renderUserField} />
      </ReferenceField>
      <DateField showTime source="takenAt" emptyText={EMPTY_TEXT} />
      <ReferenceField source="userTaken" reference="User" label="Driver" emptyText={EMPTY_TEXT} >
        <FunctionField
          label="Driver"
          render={renderUserField}
        />
      </ReferenceField>
      <DateField showTime source="completedAt" emptyText={EMPTY_TEXT} />
      <TextField source="passenger" emptyText={EMPTY_TEXT} />
      <DateField showTime source="cancelledAt" emptyText={EMPTY_TEXT} />
      <ReferenceField
        source="userCancelled"
        reference="User"
        label="Cancelled by"
        emptyText={EMPTY_TEXT}
      >
        <FunctionField
          label="Cancelled by"
          render={renderUserField}
        />
      </ReferenceField>
      <DateField
        showTime
        source="eta"
        label="Estimated time of arrival"
        emptyText={EMPTY_TEXT}
      />
      <FunctionField
        label="Total price"
        render={({ price }: any) => price / 100}
      />
    </SimpleShowLayout>
  </RAShow>
);

export default Show;
