import Icon from '@material-ui/icons/Description';
import { ResourceProps } from "react-admin";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import Show from './Show';

const resource: Partial<ResourceProps> = {
  list: List,
  create: Create,
  edit: Edit,
  icon: Icon,
  show: Show,
};

export default resource;
