import { ApolloClient } from "@apollo/client";
import {
  UploadFileDocument,
  UploadFileMutation,
  UploadFileMutationVariables,
} from "../generated/graphql";

export async function convertFileToRemote(
  file: File,
  client: ApolloClient<any>
) {
  const { data } = await client.mutate<
    UploadFileMutation,
    UploadFileMutationVariables
  >({
    mutation: UploadFileDocument,
    variables: { name: file.name },
  });

  if (!data) {
    throw new Error("uploadFile mutation has failed");
  }

  await fetch(data.uploadFile.url, {
    method: "PUT",
    body: file,
  });

  return data.uploadFile.file.id;
}
