import React from "react";
import { Edit as RAEdit, EditProps } from "react-admin";
import Form from "./Form";

const Edit: React.FC<EditProps> = (props) => (
  <RAEdit {...props}>
    <Form />
  </RAEdit>
);

export default Edit;
