import React from "react";
import {
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
} from "react-admin";
import { renderUserField } from "../../utils/common";

const Form: React.FC<Partial<SimpleFormProps>> = (props) => {
  return (
    <SimpleForm {...props}>
      <NumberInput
        source="count"
        min={1}
        validate={[required()]}
      />
      <ReferenceInput
        source="item"
        reference="Item"
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="user"
        reference="User"
        validate={required()}
      >
        <SelectInput optionText={renderUserField} />
      </ReferenceInput>
      <ReferenceInput
        source="order"
        reference="Order"
      >
        <SelectInput optionText={renderUserField} />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default Form;
