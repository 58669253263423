import React from "react";
import { Datagrid, List as RAList, ListProps, TextField } from "react-admin";

const List: React.FC<ListProps> = (props) => (
  <RAList {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </RAList>
);

export default List;
