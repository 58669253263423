import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import firebase from "firebase";

const cache = new InMemoryCache();

const setToken = (headers: any, token: string) => ({
  ...headers,
  Authorization: `Bearer ${token}`,
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
});

// const getCurrentUser = () =>
//   firebase.auth().currentUser ??
//   new Promise<firebase.User>((resolve, reject) => {
//     const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
//       if (user) {
//         resolve(user);
//         unsubscribe();
//       }
//     }, reject);
//   });

const authLink = setContext(async (_, { headers }) => {
  // const user = await getCurrentUser();
  // // get the authentication token from firebase if it exists
  // const token = await user.getIdToken();

  const token = await firebase.auth().currentUser?.getIdToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: token ? setToken(headers, token) : headers,
  };
});

const apolloClient = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, httpLink]),
});

export default apolloClient;
