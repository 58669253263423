import React from "react";
import { required, SimpleForm, SimpleFormProps, TextInput } from "react-admin";

const Form: React.FC<Partial<SimpleFormProps>> = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" validate={required()} />
  </SimpleForm>
);

export default Form;
