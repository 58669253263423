import { useCallback } from "react";
import { AuthProvider, UserIdentity } from "react-admin";
import { FirebaseAuthProvider } from "react-admin-firebase";
import { User, useUserQuery } from "../generated/graphql";

const config = {
  apiKey: "AIzaSyDOMdC0aSKXW3RdeylmYLUgNXT57C9FDrQ",
  authDomain: "magic-dragon-app.firebaseapp.com",
  databaseURL: "https://magic-dragon-app.firebaseio.com",
  projectId: "magic-dragon-app",
  storageBucket: "magic-dragon-app.appspot.com",
  messagingSenderId: "1083936382997",
  appId: "1:1083936382997:web:2fec733c6d56ecbf79ee1c",
  measurementId: "G-5N533BFMVE",
};

export default function useAuthProvider(): AuthProvider {
  useUserQuery();

  const defProvider = FirebaseAuthProvider(config, {});

  const getIdentity = useCallback<() => Promise<UserIdentity>>(
    async () => {
      const user = (await defProvider.checkAuth({}) as unknown) as User;

      return {
        ...user,
        fullName: user?.email || '',
      }
    },
    [defProvider]
  );

  return {
    ...defProvider,
    getIdentity,
  };
}
