import React from "react";
import {
  Datagrid,
  List as RAList,
  ListProps,
  TextField,
  FunctionField,
  SelectField,
} from "react-admin";
import { ParameterType } from "../../generated/graphql";

const List: React.FC<ListProps> = (props) => (
  <RAList {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <SelectField
        source="type"
        choices={[
          { id: ParameterType.Absolute, name: "Absolute" },
          { id: ParameterType.Relative, name: "Relative" },
        ]}
      />
      <FunctionField label="Value" render={({ value }: any) => value / 100} />
    </Datagrid>
  </RAList>
);

export default List;
