import React from "react";
import { NumberInput, required, SimpleForm, SimpleFormProps, TextInput } from "react-admin";

const Form: React.FC<Partial<SimpleFormProps>> = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" validate={required()} />
    <NumberInput source="numerator" min={0} />
    <NumberInput source="denominator" min={0} />
  </SimpleForm>
);

export default Form;
