import { ApolloClient } from "@apollo/client";
import * as R from "ramda";
import { CreateParams, GetListParams, UpdateParams } from "react-admin";
import { USER_FILTER_KEYS } from "../theme/constants";
import { convertFileToRemote } from "./file";

export async function extendParamsWithFile<
  Params extends CreateParams | UpdateParams
>(params: Params, client: ApolloClient<any>) {
  const filePairs = R.toPairs<any>(params.data).filter(
    ([, value]) => value && value.rawFile instanceof File
  );

  const fileKeys = R.map(([key]) => key, filePairs);
  const remoteFiles = await Promise.all(
    filePairs.map(([, value]) => convertFileToRemote(value.rawFile, client))
  );

  const dataWithFiles = R.fromPairs(R.zip(fileKeys, remoteFiles));

  return {
    ...params,
    data: R.mergeRight(params.data, dataWithFiles),
  };
}

export const extendParamsWithFilter = (
  resource: string,
  params: GetListParams
) => {
  switch (resource) {
    case "User":
      return R.over(
        R.lensProp("filter"),
        R.mapObjIndexed(
          R.ifElse(
            (_, key) => R.includes(key, USER_FILTER_KEYS),
            R.objOf("equals"),
            R.identity
          )
        ),
        params
      );
    default:
      return params;
  }
};
