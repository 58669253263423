import React from "react";
import { FileInput, ImageField, required, SimpleForm, SimpleFormProps, TextInput } from "react-admin";

const Form: React.FC<Partial<SimpleFormProps>> = (props) => (
  <SimpleForm {...props}>
    <FileInput source="imageFile" accept="image/*">
      <ImageField source="url" title="name" />
    </FileInput>
    <TextInput source="name" validate={required()} />
  </SimpleForm>
);

export default Form;
