import React from "react";
import {
  required,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from "react-admin";
import PriceInput from "../../components/PriceInput";
import { ParameterKey, ParameterType } from "../../generated/graphql";

const Form: React.FC<Partial<SimpleFormProps>> = (props) => (
  <SimpleForm {...props}>
    <SelectInput
      choices={[
        { id: ParameterKey.TaxAndFees, name: "Tax And Fees" },
        { id: ParameterKey.DeliveryPrice, name: "Delivery price" },
      ]}
      source="key"
      validate={required()}
    />
    <TextInput source="name" validate={required()} />
    <SelectInput
      choices={[
        { id: ParameterType.Absolute, name: "Absolute" },
        { id: ParameterType.Relative, name: "Relative" },
      ]}
      source="type"
      validate={required()}
    />
    <PriceInput source="value" />
  </SimpleForm>
);

export default Form;
